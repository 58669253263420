import { useAuth } from "lib/auth/exports";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getActiveModules } from "shared/utils/common-methods";
import { Sidebar } from "syngenta-digital-cropwise-react-ui-kit";
import "./sidebar.less";

export const SidebarMenu: React.FC = (props) => {
  const navigate = useNavigate();
  const { routePaths } = useAuth();
  const routes = getActiveModules(routePaths);
  const routesMap = Object.keys(routes);
  const [active, setActive] = useState("");
  const pathName = window.location.pathname.split("/");
  const currentPage = pathName[pathName.length - 1];

  return (
    <Sidebar
      data-cy="sidebar"
      theme="dark"
      selectedKeys={[active || currentPage.toUpperCase()]}
      collapsedMenu={true}
      // @ts-ignore
      menuItems={routesMap.map((route) => ({
        key: route,
        label: (routes as any)[route].name,
        icon: (
          <img
            data-cy={`${route.toLowerCase()}-sidebar-icon`}
            className={"sidebar-icon active"}
            alt={route.toLowerCase()}
            src={require(`../../assets/images/${route.toLowerCase()}.svg`)}
          />
        ),
        onClick: async (e: any) => {
          setActive(route);
          navigate(`/app/${route.toLowerCase()}`);
        },
      }))}
    />
  );
};
