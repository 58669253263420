import { useAuth } from "lib/auth/exports";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Loader } from "shared/layout/Loader";
import { getActiveModules } from "shared/utils/common-methods";
import { FrameworkByPartner } from "./frameworks-by-partner";
import NotFound from "./not-found";

export const AppRoutes = () => {
  const { routePaths } = useAuth();
  const routes: any = getActiveModules(routePaths);
  return (
    <Routes>
      {Object.keys(routes).map((route: any) => {
        return (
          <Route
            key={route}
            path={`/app/${route.toLowerCase()}/*`}
            element={
              <Suspense fallback={<Loader mask={true} message="Loading..." />}>
                {React.createElement(routes[route].path)}
              </Suspense>
            }
          />
        );
      })}
      <Route
        path="/app/frameworks-by-partner"
        element={<FrameworkByPartner />}
      />
      <Route
        path="/"
        element={<Navigate replace to="/app/frameworks-by-country" />}
      />
      <Route key="not-found" path="*" element={<NotFound />} />
    </Routes>
  );
};
