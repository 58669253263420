import { FrameworkEntity } from "generated/graphql";
import React, { createContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ModuleWrapper } from "shared/layout/exports";
import "./style.less";
import { BREADCRUMB_KEYS, CommonProps, PageFlow } from "./types";
import { FrameworksToggleAndCards } from "modules/frameworks/FrameworksToggleAndCards";

const CreateNewFramework = React.lazy(() =>
  import(`./components/framework/exports`).then(
    ({ CreateNewFrameworkHoc }) => ({ default: CreateNewFrameworkHoc })
  )
);
const PartnerDetails = React.lazy(() =>
  import(`./components/framework/exports`).then(({ PartnerDetails }) => ({
    default: PartnerDetails,
  }))
);
const Details = React.lazy(() =>
  import(`./components/exports`).then(({ Details }) => ({ default: Details }))
);

interface FrameworkContextProps {
  framework: FrameworkEntity;
  setFramework: React.Dispatch<React.SetStateAction<FrameworkEntity>>;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<CommonProps>>;
  breadcrumbItems: CommonProps;
  handleBreadcrumbClick: (
    clickedItem: keyof CommonProps,
    pageFlow: PageFlow
  ) => void;
  setFrameworkLoading: React.Dispatch<React.SetStateAction<boolean>>;
  frameworkLoading: boolean | null;
}

export const FrameworkContext = createContext<FrameworkContextProps>({
  framework: {} as FrameworkEntity,
  setFramework: () => {},
  setBreadcrumbItems: () => {},
  breadcrumbItems: {} as CommonProps,
  handleBreadcrumbClick: (clickedItem: keyof CommonProps) => {},
  setFrameworkLoading: () => {},
  frameworkLoading: null,
});

const Framework = () => {
  const [framework, setFramework] = useState<FrameworkEntity>(
    {} as FrameworkEntity
  );
  const [breadcrumbItems, setBreadcrumbItems] = useState<CommonProps>({});
  const [frameworkLoading, setFrameworkLoading] = useState<boolean>(true);

  const handleBreadcrumbClick = (
    clickedItem: keyof CommonProps | null,
    pageFlow: PageFlow
  ) => {
    if (!clickedItem) {
      return;
    }
    const allBreadcrumbKeys: (keyof CommonProps)[] = BREADCRUMB_KEYS[pageFlow];
    const clickedItemIndex = allBreadcrumbKeys.indexOf(
      clickedItem as keyof CommonProps
    );
    if (clickedItemIndex !== -1) {
      const updatedBreadcrumb: any = {} as CommonProps;
      for (let i = 0; i < clickedItemIndex; i++) {
        const moduleNameKey = allBreadcrumbKeys[i] as keyof CommonProps;
        updatedBreadcrumb[allBreadcrumbKeys[i]] =
          breadcrumbItems[moduleNameKey];
      }
      setBreadcrumbItems(updatedBreadcrumb);
    }
  };
  return (
    <ModuleWrapper
      outerModulePath={"/app/frameworks-by-country"}
      outerModuleName={"Frameworks"}
      innerModuleNames={breadcrumbItems}
    >
      <FrameworkContext.Provider
        value={{
          framework,
          setFramework,
          setBreadcrumbItems,
          breadcrumbItems,
          handleBreadcrumbClick,
          setFrameworkLoading,
          frameworkLoading,
        }}
      >
        <Routes>
          <Route
            key="createNewFramework"
            path="edit-framework/:id"
            element={<CreateNewFramework />}
          />
          <Route
            key="cloneNewFramework"
            path="clone-framework"
            element={<CreateNewFramework />}
          />
          <Route
            key="editFrameworkPartners"
            path="edit-framework/:id/edit-framework-partners"
            element={<PartnerDetails />}
          />
          <Route key="details" path=":id/*" element={<Details />} />
          <Route
            key="default"
            path="/*"
            element={<FrameworksToggleAndCards />}
          />
        </Routes>
      </FrameworkContext.Provider>
    </ModuleWrapper>
  );
};

export default Framework;
