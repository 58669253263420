import { Col, Row } from "antd";
import { Framework } from "modules/framework/types";
import React from "react";
import FrameworksByCountryCard from "./FrameworksByCountryCard";

const CreateFrameworkCardsRow = (frameworkgroup: Framework[]): JSX.Element => {
  const gutterSize: [number, number] = [16, 16];
  return (
    <Row gutter={gutterSize}>
      {frameworkgroup.map(({ id, name, country, status, version }, index) => (
        <Col key={index} xs={12} xl={8} sm={24} lg={12} xxl={6}>
          <FrameworksByCountryCard
            key={id}
            id={id}
            name={name}
            country={country}
            status={status}
            version={version}
          />
        </Col>
      ))}
    </Row>
  );
};

export default CreateFrameworkCardsRow;
