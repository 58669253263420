/* eslint-disable react-hooks/exhaustive-deps */
import { FrameworkEntity, useFrameworksQuery } from "generated/graphql";
import { FrameworkContext } from "modules/framework";
import { CommonProps } from "modules/framework/types";
import CreateFrameworkCardsRow from "modules/frameworks-by-country/CreateFrameworkCardsRow";
import React, { useContext, useEffect, useState } from "react";
import { CustomCollapse } from "shared/components/exports";
import { ErrorDisplay } from "shared/layout/Error";
import { InlineLoader } from "shared/layout/Loader";
import { ToggleButton } from "./ToggleButton";
import "./style.less";
interface Framework {
  id: string;
  country: string;
  name: string;
  status: string;
  version: any;
}
export type FrameworkType = "country" | "partner";

export const FrameworksToggleAndCards = () => {
  const { setFramework, setBreadcrumbItems } = useContext(FrameworkContext);

  useEffect(() => {
    setFramework({} as FrameworkEntity);
    setBreadcrumbItems({} as CommonProps);
  }, []);
  const {
    data: frameworksResponse,
    isLoading: isFrameworkLoading,
    isError: isFrameworkError,
  } = useFrameworksQuery({}, { cacheTime: 100 });

  const [selected, setSelected] = useState<FrameworkType>("country");
  const toggleSelection = (frameworkType: FrameworkType) => {
    setSelected(frameworkType);
  };
  const frameworksData: Framework[] =
    frameworksResponse?.frameworks.data.map(
      (framework): Framework => ({
        id: framework.id,
        country: framework.country.name,
        name: framework.name ?? "",
        status: framework.status,
        version: framework.version,
      })
    ) ?? [];

  const groupFrameworksByCountry = (frameworksData: Framework[]) => {
    const groupOfCountries = frameworksData.reduce(
      (acc: { [country: string]: Framework[] }, framework: Framework) => {
        if (!acc[framework.country]) {
          acc[framework.country] = [];
        }
        acc[framework.country].push(framework);
        return acc;
      },
      {}
    );

    return Object.entries(groupOfCountries).map(
      ([country, frameworks], index) => ({
        key: index,
        header: country,
        content: CreateFrameworkCardsRow(frameworks),
      })
    );
  };
  const CreateCollapseItems = groupFrameworksByCountry(frameworksData);

  if (isFrameworkLoading) {
    return <InlineLoader />;
  }

  if (isFrameworkError) {
    return (
      <ErrorDisplay
        title="Frameworks not loaded"
        subTitle="Please try reloading the page again"
      />
    );
  }
  return (
    <section className="frameworks-toggle-container">
      <section className="toggle-buttons-container">
        <ToggleButton
          toggleSelection={toggleSelection}
          selected={selected}
          setSelected={setSelected}
        />
      </section>
      {selected === "country" && (
        <section className="custom-collapse-container">
          <CustomCollapse
            ghost={false}
            items={CreateCollapseItems}
            defaultActiveKey={["0"]}
          />
        </section>
      )}
    </section>
  );
};
