import React from "react";
import { useTranslation } from "react-i18next";
import { openNotificationWithIcon } from "shared/utils/common-methods";
import { DeleteConfirmationPopup } from "./DeleteConfirmationPopup";

export const withDeleteModule =
  (hocProps: { [k: string]: any }) => (WrappedComponent: any) => {
    const { popupContent, popupTitleText, errorMessage } = hocProps;
    return (componentProps: any) => {
      const { t } = useTranslation();
      const onDelete = (props: any) => {
        const { deleteItemParams, deleteItem } = props;
        const deleteItemPopupParams = {
          titleText: popupTitleText,
          content: popupContent,
          onOk: () => {
            deleteYesNoQuestionSet(deleteItemParams, deleteItem, errorMessage);
          },
        };
        DeleteConfirmationPopup(deleteItemPopupParams);
      };

      const deleteYesNoQuestionSet = async (
        deleteItemParams: { [k: string]: any },
        deleteItem: any,
        errorMessage: string
      ) => {
        try {
          await deleteItem({ ...deleteItemParams });
        } catch (error: any) {
          openNotificationWithIcon({
            type: "error",
            message: errorMessage ?? t(error?.message),
          });
          console.log(error);
        }
      };
      return <WrappedComponent {...componentProps} onDelete={onDelete} />;
    };
  };
