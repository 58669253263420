import { AuthProvider } from 'lib/auth/AuthContext';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

// https://github.com/tannerlinsley/react-query/issues/1657

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      structuralSharing: false
    },
  },
});

function AppProviders(props: any): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          {props.children}
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}
export default AppProviders;
