import { DeleteTwoTone } from "@ant-design/icons";
import { Button, Card, Divider, Tooltip } from "antd";
import { FrameworkContext } from "modules/framework";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useIsFrameworkPublishedOrInReview from "shared/hooks/useIsFrameworkPublishedOrInReview";
import { ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import "./create-edit-card-component.less";
const { Meta } = Card;

export const CreateEditCardComponent = (props: any) => {
  const { frameworkLoading } = useContext(FrameworkContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    description,
    isEditPage,
    onSave,
    deleteIconID,
    loading,
    onDelete,
    disabledButton,
    isSaveButtonVisibile,
    title,
    frameworkData,
    frameworkLoading: frameworkLoadingProps,
    onCancel,
    onClone,
    deleteTooltipText,
    hideDeleteIcon,
  } = props;
  const { isFrameworkPublishedOrInReview } =
    useIsFrameworkPublishedOrInReview(frameworkData);
  const isFrameworkPartnerScreenOpen =
    title?.toLowerCase() === "framework partners";
  const isEditFrameworkPage = pathname.indexOf("/edit-framework") !== -1;
  const isDisabledContent =
    isEditFrameworkPage && isFrameworkPublishedOrInReview;
  const isDisabledSubmit = isFrameworkPublishedOrInReview && !isDisabledContent;

  const showCloneButton = () => {
    const titleInLowerCase = title?.toLowerCase();
    return titleInLowerCase.includes("edit framework");
  };

  return (
    <Card
      data-cy="create-edit-card"
      className="card"
      actions={[
        <div className="create-edit-card-footer" key="card-footer">
          <Button
            data-cy="cancel"
            style={{ marginLeft: 24, backgroundColor: "white" }}
            onClick={onCancel}
          >
            {isFrameworkPartnerScreenOpen || isDisabledSubmit
              ? t("Back")
              : t("Cancel")}
          </Button>
          {(!isDisabledSubmit || isSaveButtonVisibile) && (
            <Button
              data-cy="save"
              className="btn-green"
              type={ButtonType.primary}
              style={{ marginRight: 24 }}
              onClick={onSave}
              loading={loading}
              disabled={disabledButton}
            >
              {isEditPage ? "Save" : "Create"}
            </Button>
          )}
        </div>,
      ]}
    >
      <Meta
        avatar={
          <>
            {showCloneButton() && (
              <Button
                data-cy="clone"
                className="defaultButton"
                style={{
                  marginRight: isFrameworkPublishedOrInReview ? 0 : 15,
                  backgroundColor: "white",
                }}
                onClick={onClone}
              >
                {t("Clone")}
              </Button>
            )}
            {!isFrameworkPublishedOrInReview && !hideDeleteIcon && (
              <Tooltip placement="top" title={t(deleteTooltipText)}>
                <span
                  role="button"
                  onKeyDown={onDelete}
                  data-cy="delete-icon"
                  onClick={onDelete}
                  style={{ cursor: "pointer" }}
                  id={deleteIconID}
                >
                  <DeleteTwoTone twoToneColor="#CF3537" />
                </span>
              </Tooltip>
            )}
          </>
        }
        title={title}
        data-cy="card-meta"
        description={description}
      />
      <Divider className="divider" />
      {props.children}
    </Card>
  );
};
