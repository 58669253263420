import { Checkbox, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { onDropDownOpen } from "shared/utils/common-methods";
import { DropdownSelectAll } from "./DropdownSelectAll";

export const MultiSelectComponent = ({
  form,
  list,
  formItemLabel,
  labelToShow,
  selectedItems,
  disabled,
  defaultOpen,
  disabledID,
  defaultValue,
  autoFocus,
  rules,
  listHeight,
  onChange,
  popupMatchSelectWidth,
  loading,
  bordered,
  name,
  shouldUpdate,
}: {
  form: any;
  list: Array<any>;
  formItemLabel?: string;
  labelToShow: string;
  selectedItems: string[];
  disabled?: boolean;
  defaultOpen?: boolean;
  disabledID?: any;
  defaultValue?: string;
  autoFocus?: boolean;
  rules?: any;
  listHeight?: any;
  onChange?: (values: string[]) => void;
  popupMatchSelectWidth?: boolean;
  loading?: boolean;
  bordered?: "borderless" | "outlined" | "filled";
  name: string | string[];
  shouldUpdate?: boolean;
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const [labels, setLabels] = useState<Array<string>>([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setLabels(selectedItems || []);
  }, [selectedItems]);

  useEffect(() => {
    return () => {
      onDropDownOpen(false);
    };
  }, []);

  let handleSelectAll = () => {
    const isSelectAll = !selectAll;
    setSelectAll(isSelectAll);
    if (isSelectAll) {
      handleChange(list.map((item) => item.id));
      onChange?.(list.map((item) => item.id));
    } else {
      handleChange(disabledID ?? []);
      onChange?.(disabledID ?? []);
    }
  };

  let filterData = function () {
    const [input, option] = arguments;
    return (option?.label as string)
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleChange = (values: string[]) => {
    if (values?.length === 0) {
      setSelectAll(false);
    } else if (values?.length === list.length) {
      setSelectAll(true);
    }
    setLabels(values);
    if (typeof name === "string") {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        [name]: values,
      });
    } else {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        [name[0]]: { ...form.getFieldValue(name[0]), [name[1]]: values },
      });
    }
  };

  const toggleSelectAll = () => {
    setSelectAll(false);
  };

  return (
    <Form.Item
      label={formItemLabel}
      name={name}
      rules={rules}
      shouldUpdate={shouldUpdate}
    >
      <Select
        onDeselect={toggleSelectAll}
        data-cy={labelToShow}
        loading={loading}
        variant={bordered ?? "borderless"}
        popupClassName="selectdropdown"
        placeholder={t(`Select ${labelToShow}`)}
        size="large"
        style={{
          color: "#005693",
          lineHeight: "18px !important",
        }}
        mode="multiple"
        optionLabelProp="label"
        showSearch={true}
        filterOption={filterData}
        onDropdownVisibleChange={onDropDownOpen}
        popupMatchSelectWidth={popupMatchSelectWidth ?? false}
        onChange={(values: string[]) => {
          handleChange(values);
          onChange?.(values);
        }}
        notFoundContent={`No ${labelToShow} found`}
        dropdownRender={
          list?.length
            ? (menu) => (
                <DropdownSelectAll
                  selectAll={selectAll}
                  handleSelectAll={handleSelectAll}
                  menu={menu}
                />
              )
            : undefined
        }
        maxTagCount={disabled ? selectedItems?.length : 1}
        defaultOpen={defaultOpen}
        autoFocus={autoFocus}
        disabled={disabled}
        defaultValue={defaultValue ?? form.getFieldValue(name)}
        listHeight={listHeight ?? 200}
        dropdownStyle={{ width: 300 }}
      >
        {list?.map((item: any) => (
          <Option
            disabled={disabledID?.includes(item.id)}
            key={item.id}
            value={item.id}
            className={`syt-option multiselect--margin-left`}
            style={{ height: 32 }}
            label={item.name}
          >
            <div>
              <span
                style={{
                  pointerEvents: "all",
                  marginRight: "12px",
                }}
              >
                <Checkbox
                  data-cy={labels?.includes(item.id) || item.id === disabledID}
                  checked={labels?.includes(item.id) || item.id === disabledID}
                  className="checkbox"
                />
              </span>
              {item.name}
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
