import React from "react";
import { useTranslation } from "react-i18next";
import { Result } from "syngenta-digital-cropwise-react-ui-kit";
import maintainenceIcon from "../../assets/images/site-maintenance.jpg";

export const SystemDown: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        backgroundColor: "#F5F8FC",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "13vh",
          left: "15vw",
          width: "70%",
          height: "fit-content",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "min-content",
            height: "350px",
            textAlign: "center",
            position: "relative",
            left: "1vw",
            bottom: "4.5vh",
          }}
        >
          <div>
            <Result icon={<img src={maintainenceIcon} alt="" />}></Result>
          </div>
          <div
            style={{
              backgroundColor: "#f3f4f6",
              position: "relative",
              bottom: "10vh",
              left: "1.6vw",
              width: "27.5vw",
            }}
          >
            <h3
              style={{
                fontWeight: "bolder",
                color: "black",
                fontSize: "19px",
                fontFamily: "sans-serif",
                marginBottom: "6vh",
              }}
            >
              {t("Sorry, the page cannot be opened!")}
            </h3>
            <h1 style={{ fontSize: "19px", fontFamily: "sans-serif" }}>
              {t("Currently down for maintenance. Please check back later.")}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};
