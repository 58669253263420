import React from "react";
import { ContentLoader, ErrorDisplay } from "shared/layout/exports";

const NotFound = () => {
  return (
    <ContentLoader>
      <ErrorDisplay
        title="Page not found"
        subTitle="The page you are looking for does not exist."
      />
    </ContentLoader>
  );
};

export default NotFound;
