import axios from "axios";
import queryString from "query-string";

const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;
const OAUTH_SECRET = process.env.REACT_APP_OAUTH_SECRET ?? "";

const O_AUTH = {
  responseCode: "code",
  clientId: OAUTH_CLIENT_ID,
};
const ACCOUNTS_ENDPOINT = process.env.REACT_APP_ACCOUNTS_ENDPOINT;

const API_URL = process.env.REACT_APP_BASE_API_URL;

const CLIENTIDAUTHCODE_ENC = btoa(`${OAUTH_CLIENT_ID}:${OAUTH_SECRET}`);

const AUTH_HEADERS = {
  "Content-Type": "application/x-www-form-urlencoded",
  authorization: `Basic ${CLIENTIDAUTHCODE_ENC}`,
};

export const getAccessToken = () => {
  try {
    return JSON.parse(localStorage.getItem("tokens") ?? "").access_token;
  } catch (e) {
    return null;
  }
};

export const isAccessTokenAvailable = () => !!localStorage.getItem("tokens");

export const getOAuthUrl = () => {
  return `https://${ACCOUNTS_ENDPOINT}/oauth/authorize?response_type=${O_AUTH.responseCode}&client_id=${O_AUTH.clientId}&redirect_uri=${window.location.origin}`;
};

export const authService = (function () {
  const clearAccessToken = () => localStorage.removeItem("tokens");
  const token = () => localStorage.getItem("tokens") ?? "";
  const isAccessTokenAvailable = () => !!localStorage.getItem("tokens");
  const getAccessToken = () => {
    try {
      return JSON.parse(token()).access_token;
    } catch (e) {
      return null;
    }
  };
  const getOAuthUrl = () => {
    return `https://${ACCOUNTS_ENDPOINT}/oauth/authorize?response_type=${O_AUTH.responseCode}&client_id=${O_AUTH.clientId}&redirect_uri=${window.location.origin}`;
  };

  const performLogin = async (code: any) => {
    const authParams = {
      grant_type: "authorization_code",
      client_id: O_AUTH.clientId,
      redirect_uri: `${window.location.origin}`,
      code,
    };
    const res = await axios.post(
      `${API_URL}/oauth/token`,
      queryString.stringify(authParams as any),
      { headers: AUTH_HEADERS }
    );
    const tokens = res.data;
    localStorage.setItem("tokens", JSON.stringify(tokens));
    return tokens;
  };

  const performAdminValidation = async (payloadValidation: any) => {
    const headers = {
      "Content-Type": "application/json",
      authorization: `Bearer ${getAccessToken()}`,
    };

    const res = await axios.post(
      `${API_URL}/v2/auth/verify`,
      payloadValidation,
      { headers }
    );

    const userValidation = res.data;
    localStorage.setItem("userValidation", JSON.stringify(userValidation));
    if (userValidation?.allowed_operations?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getAccountId = async () => {
    const data = await currentUser();
    return data?.id;
  };

  const getUserRole = async () => {
    const headers = {
      "Content-Type": "application/json",
      authorization: `Bearer ${getAccessToken()}`,
    };
    try {
      const accountId = await getAccountId();
      const res = await axios.get(`${API_URL}/v2/accounts/${accountId}/roles`, {
        headers,
      });
      const roles = res.data?.content?.map((role: any) => role.role_id);
      const superAdminRoles = (process.env.REACT_APP_SUPER_ADMIN || "").split(
        ","
      );
      const requiredRoles = filterRequiredRoles(roles, superAdminRoles);
      const role = determineRole(requiredRoles, superAdminRoles);

      return role;
    } catch (error) {
      console.log(error);
    }
  };
  const filterRequiredRoles = (roles: string[], superAdminRoles: string[]) => {
    return roles.filter(
      (roleID) =>
        roleID === process.env.REACT_APP_ADMIN ||
        superAdminRoles.includes(roleID)
    );
  };

  const determineRole = (
    requiredRoles: string[],
    superAdminRoles: string[]
  ) => {
    const superAdminRole = superAdminRoles.find((role) =>
      requiredRoles.includes(role)
    );
    if (superAdminRole) {
      return superAdminRole;
    } else if (requiredRoles.includes(process.env.REACT_APP_ADMIN!)) {
      return process.env.REACT_APP_ADMIN;
    }
    return "";
  };

  const requestAdminAccess = async () => {
    const headers = {
      "Content-Type": "application/json",
      authorization: `Bearer ${getAccessToken()}`,
    };
    try {
      await axios.post(
        `${API_URL}/v2/notifications/slack/dev-auth`,
        {
          userName: "postman",
          userEmail: "postman@post.com",
          env: process.env.REACT_APP_ENV?.toUpperCase(),
        },
        { headers }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const currentUser = async () => {
    const headers = {
      "Content-Type": "application/json",
      authorization: `Bearer ${getAccessToken()}`,
    };

    const res = await axios.get(`${API_URL}/v2/accounts/me`, { headers });
    return res.data;
  };

  const logout = async () => {
    clearAccessToken();
    window.location.href = `https://${ACCOUNTS_ENDPOINT}/exit?continue=${window.location.origin}`;
    return "Time for a nap. Logging out..";
  };

  return {
    performLogin,
    currentUser,
    isAccessTokenAvailable,
    getOAuthUrl,
    logout,
    performAdminValidation,
    requestAdminAccess,
    getUserRole,
  };
})();
