import React from "react";
import { Col, Row } from "antd";
import { CustomCollapse } from "shared/components/exports";
import FrameworkByPartnerCard from "./FrameworkByPartnerCard";
import "./style.less";

const createFrameworkCardsRow = (names: string[]) => (
  <Row gutter={16}>
    {names.map((name, index) => (
      <Col key={index} xs={24} xl={6}>
        <FrameworkByPartnerCard name={name} />
      </Col>
    ))}
  </Row>
);

const frameworkNames = [
  [
    "ADM Framework 2025",
    "ADM Framework 2024",
    "ADM Framework 2023 B",
    "ADM Framework 2023",
  ],
  [
    "ADM Framework 2024",
    "ADM Framework 2023",
    "ADM Framework 2022 B",
    "ADM Framework 2022",
  ],
];

const frameworkByPartner = frameworkNames.map((names, index) => ({
  key: index,
  header: "ADM",
  content: createFrameworkCardsRow(names),
}));

export const FrameworkByPartner = () => {
  return (
    <div>
      <CustomCollapse
        ghost={false}
        items={frameworkByPartner}
        defaultActiveKey={["0"]}
      />
    </div>
  );
};
