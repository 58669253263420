import { ConfigProvider } from "antd";
import React, { type ReactNode } from "react";
import { AntdConfigProvider } from "syngenta-digital-cropwise-react-ui-kit";

interface IThemeProviderProps {
  themeName?: string;
  children: ReactNode;
}
export const ThemeProvider = ({ children, themeName }: IThemeProviderProps) => (
  <AntdConfigProvider prefixCls="syngenta-ant">
    <ConfigProvider theme={{ hashed: false }} prefixCls="syngenta-ant">
      {children}
    </ConfigProvider>
  </AntdConfigProvider>
);
