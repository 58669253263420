import { Modal } from "antd";
import "antd/dist/reset.css";
import "./style.less";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";

export function DeleteConfirmationPopup(props: any) {
  let {
    content,
    titleText,
    onOk,
  }: { content: string; titleText: string; onOk: Function } = props;

  Modal.confirm({
    title: `Are you sure you want to delete ${titleText}?`,
    icon: <ExclamationCircleOutlined />,
    content,
    cancelText: "Cancel",
    okText: "Delete",
    onOk: () => {
      onOk();
    },
  });
}
