import { useQuery, useInfiniteQuery, useMutation, UseQueryOptions, UseInfiniteQueryOptions, UseMutationOptions } from 'react-query';
import { ferry } from 'lib/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

/** Specifies Additional Acres needed or not */
export enum AdditionalAcres {
  Neither = 'NEITHER',
  No = 'NO',
  Yes = 'YES'
}

export type AdditionalInformation = {
  __typename?: 'AdditionalInformation';
  createdAt: Scalars['DateTime']['output'];
  drawer_description: Scalars['String']['output'];
  drawer_title: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: Question;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AdditionalInformationInput = {
  drawer_description: Scalars['String']['input'];
  drawer_title: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type AuditLog = {
  __typename?: 'AuditLog';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  event_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  object_id?: Maybe<Scalars['String']['output']>;
  object_name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user_id?: Maybe<Scalars['String']['output']>;
};

/** Base crop details */
export type BaseCropDetails = {
  __typename?: 'BaseCropDetails';
  default_variety_id: Scalars['String']['output'];
  image_path: Scalars['String']['output'];
  varieties_ids: Array<Scalars['String']['output']>;
  wk_slug: Scalars['String']['output'];
};

export type BaseCropDetailsInput = {
  default_variety_id: Scalars['String']['input'];
  image_path: Scalars['String']['input'];
  varieties_ids: Array<Scalars['String']['input']>;
  wk_slug: Scalars['String']['input'];
};

export type BaseCropMap = {
  __typename?: 'BaseCropMap';
  active: Scalars['Boolean']['output'];
  baseCropId: Scalars['String']['output'];
  basecropQuestions?: Maybe<Array<BaseCropQuestionEntity>>;
  country: Country;
  created: Scalars['DateTime']['output'];
  cropDetails: Array<BaseCropDetails>;
  frameworks: Array<FrameworkEntity>;
  isOtherCrop?: Maybe<Scalars['Boolean']['output']>;
  language: Language;
  name: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  yield?: Maybe<YieldUnit>;
};

export type BaseCropMapmetadata = {
  __typename?: 'BaseCropMapmetadata';
  totalCount: Scalars['Float']['output'];
};

export type BaseCropQuerySearch = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BaseCropQuerySort = {
  order?: InputMaybe<SortOrder>;
  sortKey?: InputMaybe<BaseCropSortKeys>;
};

export type BaseCropQuestionEntity = {
  __typename?: 'BaseCropQuestionEntity';
  baseCropBaseCropId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  isIrrigated: Scalars['Boolean']['output'];
  isRainfed: Scalars['Boolean']['output'];
  questionId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Values for BaseCrop Module Sort Keys */
export enum BaseCropSortKeys {
  Active = 'ACTIVE',
  Created = 'CREATED',
  Name = 'NAME',
  Updated = 'UPDATED'
}

export type CloneFrameworkInput = {
  countryId: Scalars['String']['input'];
  cropIds: Array<Scalars['String']['input']>;
  cropYear: Scalars['String']['input'];
  id: Scalars['String']['input'];
  partners: Array<Scalars['String']['input']>;
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  countriesLanguages?: Maybe<Array<CountryToLanguage>>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type CountryQuerySearch = {
  isActive: Scalars['Boolean']['input'];
};

export type CountrySearchRequest = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CountryToLanguage = {
  __typename?: 'CountryToLanguage';
  country: Country;
  country_id: Scalars['String']['output'];
  language: Language;
  locale: Scalars['String']['output'];
};

export type CreateAdditionalInformationInput = {
  drawer_description: Scalars['String']['input'];
  drawer_title: Scalars['String']['input'];
  question_id: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateBaseCropMapInput = {
  active: Scalars['Boolean']['input'];
  baseCropId: Scalars['String']['input'];
  countryId: Scalars['String']['input'];
  cropDetails?: InputMaybe<Array<BaseCropDetailsInput>>;
  languageId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  yieldId?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateCropInput = {
  mapToCrop: Scalars['String']['input'];
  name: Scalars['String']['input'];
  translatedCropNameInput: Array<TranslatedCropNameInput>;
};

export type CreateDriverInput = {
  context?: InputMaybe<Scalars['String']['input']>;
  frameworkId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  outcomeIds: Array<Scalars['Float']['input']>;
  skip?: InputMaybe<SkipObjectInput>;
  soaidPrefix?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDriverOpportunityInput = {
  credit?: InputMaybe<Scalars['Float']['input']>;
  recommendation?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDriverStrengthInput = {
  credit?: InputMaybe<Scalars['Float']['input']>;
  strength?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFrameworkInput = {
  countryId: Scalars['String']['input'];
  cropIds: Array<Scalars['String']['input']>;
  cropYear: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  languages: Array<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partners: Array<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOpportunityInput = {
  credit: Scalars['Float']['input'];
  driverId: Scalars['Float']['input'];
  recommendation: Scalars['String']['input'];
};

export type CreateOutcomeInput = {
  frameworkId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  templates: Array<Scalars['Float']['input']>;
  type: Scalars['String']['input'];
};

export type CreatePartnerInput = {
  name: Scalars['String']['input'];
};

export type CreateQuestionInput = {
  cropQuestions?: InputMaybe<Array<CropQuestionInput>>;
  driverIds: Array<Scalars['Float']['input']>;
  frameworkId: Scalars['String']['input'];
  isPracticeQuestion: Scalars['Boolean']['input'];
  labels?: InputMaybe<Array<LabelTypeInput>>;
  makeDecision?: InputMaybe<Scalars['Boolean']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  templateId?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateQuestionOpportunityAndStrengthInput = {
  createOpportunities: Array<CreateDriverOpportunityInput>;
  createQuestion: Array<CreateYesNoQuestionInput>;
  createStrengths: Array<CreateDriverStrengthInput>;
  driver: CreateDriverInput;
};

export type CreateStrengthsInput = {
  credit: Scalars['Float']['input'];
  driverId: Scalars['Float']['input'];
  strength: Scalars['String']['input'];
};

export type CreateTemplateInput = {
  frameworkId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateYesNoQuestionInput = {
  isPracticeQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<LabelTypeInput>>;
  makeDecision?: InputMaybe<Scalars['Boolean']['input']>;
  rating: Scalars['Float']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateYesNoQuestionOutput = {
  __typename?: 'CreateYesNoQuestionOutput';
  createOpportunities: Array<Opportunity>;
  createQuestions?: Maybe<Array<Question>>;
  createStrengths: Array<Strengths>;
  driver?: Maybe<Driver>;
};

export type Crop = {
  __typename?: 'Crop';
  created: Scalars['DateTime']['output'];
  cropQuestions?: Maybe<Array<CropQuestionEntity>>;
  frameworks: Array<FrameworkEntity>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  translatedCropName: Array<TranslatedCropName>;
  updated: Scalars['DateTime']['output'];
};

export type CropQuestionEntity = {
  __typename?: 'CropQuestionEntity';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  cropId: Scalars['ID']['output'];
  isIrrigated: Scalars['Boolean']['output'];
  isRainfed: Scalars['Boolean']['output'];
  questionId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CropQuestionInput = {
  cropId: Scalars['ID']['input'];
  isIrrigated: Scalars['Boolean']['input'];
  isRainfed: Scalars['Boolean']['input'];
};

export type Driver = {
  __typename?: 'Driver';
  context?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  driverLabel: Scalars['String']['output'];
  framework: FrameworkEntity;
  frameworkId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isAvailableInMainTemplate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  outcomes?: Maybe<Array<Outcome>>;
  questionCount: Scalars['Float']['output'];
  questions: Array<Question>;
  skip?: Maybe<SkipObject>;
  soaidPrefix?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
};

export type DriverQuerySearch = {
  frameworkId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  outcomes?: InputMaybe<Array<OutcomeSearchRequest>>;
};

export type DriverQuerySort = {
  order?: InputMaybe<SortOrder>;
  sortKey?: InputMaybe<DriverSortKeys>;
};

export type DriverSearchRequest = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Values for Driver Module Sort Keys */
export enum DriverSortKeys {
  Created = 'CREATED',
  Id = 'ID',
  Name = 'NAME',
  Number = 'NUMBER',
  Updated = 'UPDATED'
}

export type Drivermetadata = {
  __typename?: 'Drivermetadata';
  totalCount: Scalars['Float']['output'];
};

export type FrameworkEntity = {
  __typename?: 'FrameworkEntity';
  baseCrops?: Maybe<Array<BaseCropMap>>;
  country: Country;
  countryId: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  cropYear: Scalars['String']['output'];
  crops?: Maybe<Array<Crop>>;
  deletedAt: Scalars['DateTime']['output'];
  frameworkPartner?: Maybe<Array<FrameworkPartnerEntity>>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isTemplateFramework: Scalars['Boolean']['output'];
  languages?: Maybe<Array<Language>>;
  name?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  unit: Unit;
  unitId: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
  versionNotes?: Maybe<Scalars['String']['output']>;
};

export type FrameworkEntitymetadata = {
  __typename?: 'FrameworkEntitymetadata';
  totalCount: Scalars['Float']['output'];
};

export type FrameworkPartnerEntity = {
  __typename?: 'FrameworkPartnerEntity';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  framework: FrameworkEntity;
  frameworkId: Scalars['String']['output'];
  is_unique_identifier_required: Scalars['Boolean']['output'];
  partner: PartnerEntity;
  partnerId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FrameworkPartnerUpdate = {
  is_unique_identifier_required: Scalars['Boolean']['input'];
  partnerId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type FrameworkQuerySearch = {
  country?: InputMaybe<CountrySearchRequest>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  cropYear?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<LanguageSearchRequest>>;
  name?: InputMaybe<Scalars['String']['input']>;
  partners?: InputMaybe<Array<PartnerSearchRequest>>;
  status?: InputMaybe<Array<StatusType>>;
  unit?: InputMaybe<UnitSearchRequest>;
  unitId?: InputMaybe<Scalars['String']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type FrameworkQuerySort = {
  order?: InputMaybe<SortOrder>;
  sortKey?: InputMaybe<FrameworkSortKeys>;
};

/** Values for Framework Module Sort Keys */
export enum FrameworkSortKeys {
  Created = 'CREATED',
  CreatedBy = 'CREATED_BY',
  CropYear = 'CROP_YEAR',
  Id = 'ID',
  IsActive = 'IS_ACTIVE',
  Name = 'NAME',
  Status = 'STATUS',
  Updated = 'UPDATED',
  UpdatedBy = 'UPDATED_BY',
  Version = 'VERSION'
}

/** Types of a Irrigation */
export enum IrrigationType {
  Irrigation = 'IRRIGATION',
  Neither = 'NEITHER',
  Rainfed = 'RAINFED'
}

/** Fields of a Label */
export type LabelType = {
  __typename?: 'LabelType';
  answer?: Maybe<Scalars['String']['output']>;
  labelId?: Maybe<Scalars['Float']['output']>;
  labelName?: Maybe<Scalars['String']['output']>;
  relatedReportField?: Maybe<Scalars['String']['output']>;
  skipValue?: Maybe<QuestionSkipType>;
  soa_id?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type LabelTypeInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  labelId?: InputMaybe<Scalars['Float']['input']>;
  labelName?: InputMaybe<Scalars['String']['input']>;
  relatedReportField?: InputMaybe<Scalars['String']['input']>;
  skipValue?: InputMaybe<QuestionSkipType>;
  soa_id?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type Language = {
  __typename?: 'Language';
  created: Scalars['DateTime']['output'];
  frameworks: Array<FrameworkEntity>;
  id: Scalars['String']['output'];
  languagesCountries?: Maybe<Array<CountryToLanguage>>;
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shortLabel: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type LanguageQuerySort = {
  order?: InputMaybe<SortOrder>;
  sortKey?: InputMaybe<LanguageSortKeys>;
};

export type LanguageSearchRequest = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Values for Language Module Sort Keys */
export enum LanguageSortKeys {
  Created = 'CREATED',
  Id = 'ID',
  Name = 'NAME',
  Updated = 'UPDATED'
}

export type Mutation = {
  __typename?: 'Mutation';
  addLanguagesToCountry: Country;
  baseCropFrameworkMapping: Scalars['String']['output'];
  bulkCreateQuestions: Array<Question>;
  cloneFramework: FrameworkEntity;
  createAdditionalInformation: AdditionalInformation;
  createBaseCropMap: BaseCropMap;
  createCrop: Crop;
  createCropQuestionMapping: Scalars['String']['output'];
  createDriver: Driver;
  createFramework: FrameworkEntity;
  createManyBaseCropMap: Array<BaseCropMap>;
  createOpportunities: Array<Opportunity>;
  createOutcome: Outcome;
  createPartner: PartnerEntity;
  createQuestion: Question;
  createQuestionsOpportunitiesAndStrengths: CreateYesNoQuestionOutput;
  createStrengths: Array<Strengths>;
  createTemplate: Template;
  createUSCropsForExploration: Scalars['String']['output'];
  dataSync: Scalars['String']['output'];
  harddeleteFramework: FrameworkEntity;
  migrationInverseSkipLogic: Scalars['String']['output'];
  recreateFramework: Scalars['String']['output'];
  removeAdditionalInformation: Scalars['String']['output'];
  removeCropQuestionMapping: Scalars['String']['output'];
  removeDriver: Scalars['String']['output'];
  removeDriverCreatedFromMainTemplate: Scalars['String']['output'];
  removeFramework: FrameworkEntity;
  removeLanguagesFromCountry: Country;
  removeOpportunity: Scalars['String']['output'];
  removeOutcome: Scalars['String']['output'];
  removePartner: Scalars['String']['output'];
  removeQuestion: Scalars['String']['output'];
  removeQuestionsAndOpportunities: Scalars['String']['output'];
  removeStrengths: Scalars['String']['output'];
  removeTemplate: Scalars['String']['output'];
  removeUnit: Scalars['String']['output'];
  saveMainTemplate: Scalars['String']['output'];
  sendDataSyncMessage: Scalars['String']['output'];
  updateAllCrops: Array<Crop>;
  updateBaseCropMap: BaseCropMap;
  updateCountry: Country;
  updateCrop: Crop;
  updateDriver: Driver;
  updateDriverLabel: Scalars['String']['output'];
  updateFramework: FrameworkEntity;
  updateFrameworkPartnerMapping: Scalars['String']['output'];
  updateFrameworkStrengths: Array<Strengths>;
  updateOpportunity: Opportunity;
  updateOpportunityByDriverNumber: Scalars['String']['output'];
  updateOutcome: Outcome;
  updatePartner: PartnerEntity;
  updatePartnerIdentifier: Scalars['String']['output'];
  updatePartnerlockStatus: Array<FrameworkPartnerEntity>;
  updateQuestion: Question;
  updateQuestionForMultipleFrameworks: Scalars['String']['output'];
  updateQuestionsOpportunitiesAndStrengths: UpdateYesNoQuestionOutput;
  updateStrengthByDriverNumber: Scalars['String']['output'];
  updateStrengths: Strengths;
  updateTemplate: Template;
  updateYieldUnit: YieldUnit;
  validateDriver: ValidateDriverOutput;
};


export type MutationAddLanguagesToCountryArgs = {
  addLanguagesToCountry: UpdateCountryLangaugeInput;
};


export type MutationBulkCreateQuestionsArgs = {
  bulCreateQuestionsInput: Array<CreateQuestionInput>;
};


export type MutationCloneFrameworkArgs = {
  cloneFrameworkInput: CloneFrameworkInput;
};


export type MutationCreateAdditionalInformationArgs = {
  createAdditionalInformationInput: CreateAdditionalInformationInput;
};


export type MutationCreateBaseCropMapArgs = {
  createBaseCropMapInput: CreateBaseCropMapInput;
};


export type MutationCreateCropArgs = {
  createCropInput: CreateCropInput;
};


export type MutationCreateCropQuestionMappingArgs = {
  crops: CropQuestionInput;
  questionId: Scalars['String']['input'];
};


export type MutationCreateDriverArgs = {
  createDriverInput: CreateDriverInput;
};


export type MutationCreateFrameworkArgs = {
  createFrameworkInput: CreateFrameworkInput;
};


export type MutationCreateManyBaseCropMapArgs = {
  createBaseCropMapInput: Array<CreateBaseCropMapInput>;
};


export type MutationCreateOpportunitiesArgs = {
  createOpportunitiesInput: Array<CreateOpportunityInput>;
};


export type MutationCreateOutcomeArgs = {
  createOutcomeInput: CreateOutcomeInput;
};


export type MutationCreatePartnerArgs = {
  createPartnerInput: CreatePartnerInput;
};


export type MutationCreateQuestionArgs = {
  createQuestionInput: CreateQuestionInput;
};


export type MutationCreateQuestionsOpportunitiesAndStrengthsArgs = {
  createYesNoQuestions: CreateQuestionOpportunityAndStrengthInput;
};


export type MutationCreateStrengthsArgs = {
  createStrengthsInput: Array<CreateStrengthsInput>;
};


export type MutationCreateTemplateArgs = {
  createTemplateInput: CreateTemplateInput;
};


export type MutationCreateUsCropsForExplorationArgs = {
  countryId: Scalars['String']['input'];
};


export type MutationHarddeleteFrameworkArgs = {
  id: Scalars['String']['input'];
};


export type MutationRecreateFrameworkArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveAdditionalInformationArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveCropQuestionMappingArgs = {
  cropId?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['String']['input'];
};


export type MutationRemoveDriverArgs = {
  id: Scalars['Float']['input'];
};


export type MutationRemoveDriverCreatedFromMainTemplateArgs = {
  id: Scalars['Float']['input'];
};


export type MutationRemoveFrameworkArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveLanguagesFromCountryArgs = {
  removeLanguagesFromCountry: UpdateCountryLangaugeInput;
};


export type MutationRemoveOpportunityArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveOutcomeArgs = {
  id: Scalars['Float']['input'];
};


export type MutationRemovePartnerArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveQuestionArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveQuestionsAndOpportunitiesArgs = {
  driverId: Scalars['Float']['input'];
};


export type MutationRemoveStrengthsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveTemplateArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveUnitArgs = {
  id: Scalars['String']['input'];
};


export type MutationSaveMainTemplateArgs = {
  id: Scalars['String']['input'];
};


export type MutationSendDataSyncMessageArgs = {
  message: Scalars['String']['input'];
};


export type MutationUpdateBaseCropMapArgs = {
  updateBaseCropMapInput: UpdateBaseCropMapInput;
};


export type MutationUpdateCountryArgs = {
  updateCountryInput: UpdateCountryInput;
};


export type MutationUpdateCropArgs = {
  updateCropInput: UpdateCropInput;
};


export type MutationUpdateDriverArgs = {
  updateDriverInput: UpdateDriverInput;
};


export type MutationUpdateFrameworkArgs = {
  updateFrameworkInput: UpdateFrameworkInput;
};


export type MutationUpdateFrameworkPartnerMappingArgs = {
  updateFrameworkPartnerInput: UpdateFrameworkPartnerInput;
};


export type MutationUpdateFrameworkStrengthsArgs = {
  updateFrameworkStrengthsInput: UpdateFrameworkStrengthsInput;
};


export type MutationUpdateOpportunityArgs = {
  updateOpportunityInput: UpdateOpportunityInput;
};


export type MutationUpdateOpportunityByDriverNumberArgs = {
  UpdateOpportunityByDriverNumberInput: UpdateOpportunityByDriverNumberInput;
};


export type MutationUpdateOutcomeArgs = {
  updateOutcomeInput: UpdateOutcomeInput;
};


export type MutationUpdatePartnerArgs = {
  updatePartnerInput: UpdatePartnerInput;
};


export type MutationUpdatePartnerlockStatusArgs = {
  partnerLockStatusInput: PartnerLockStatusInput;
};


export type MutationUpdateQuestionArgs = {
  updateQuestionInput: UpdateQuestionInput;
};


export type MutationUpdateQuestionForMultipleFrameworksArgs = {
  updateQuestionForMultipleFrameworks: UpdateQuestionForMultipleFrameworksInput;
};


export type MutationUpdateQuestionsOpportunitiesAndStrengthsArgs = {
  updateYesNoQuestions: UpdateQuestionOpportunityStrengthInput;
};


export type MutationUpdateStrengthByDriverNumberArgs = {
  UpdateStrengthByDriverNumberInput: UpdateStrengthByDriverNumberInput;
};


export type MutationUpdateStrengthsArgs = {
  updateStrengthsInput: UpdateStrengthsInput;
};


export type MutationUpdateTemplateArgs = {
  updateTemplateInput: UpdateTemplateInput;
};


export type MutationUpdateYieldUnitArgs = {
  updateYieldUnitInput: UpdateYieldUnitInput;
};


export type MutationValidateDriverArgs = {
  validateCreateDriver: ValidateDriverInput;
};

export type Opportunity = {
  __typename?: 'Opportunity';
  created: Scalars['DateTime']['output'];
  credit: Scalars['Float']['output'];
  driver: Driver;
  driverId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  recommendation: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type OpportunityDriverQuerySearch = {
  driverId?: InputMaybe<Scalars['Float']['input']>;
};

export type OpportunityInput = {
  credit?: InputMaybe<Scalars['Float']['input']>;
  driverId?: InputMaybe<Scalars['Float']['input']>;
  recommendation?: InputMaybe<Scalars['String']['input']>;
};

export type OpportunityQuerySearch = {
  credit: Scalars['Float']['input'];
  driverId: Scalars['Float']['input'];
};

export type Outcome = {
  __typename?: 'Outcome';
  created: Scalars['DateTime']['output'];
  driverCount: Scalars['Float']['output'];
  drivers: Array<Driver>;
  framework: FrameworkEntity;
  frameworkId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isAvailableInMainTemplate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  templates?: Maybe<Array<Template>>;
  type: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type OutcomeQuerySearch = {
  frameworkId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  templates?: InputMaybe<Array<TemplateSearchRequest>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type OutcomeQuerySort = {
  order?: InputMaybe<SortOrder>;
  sortKey?: InputMaybe<OutcomeSortKeys>;
};

export type OutcomeSearchRequest = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Values for Outcome Module Sort Keys */
export enum OutcomeSortKeys {
  Created = 'CREATED',
  Id = 'ID',
  Name = 'NAME',
  Number = 'NUMBER',
  Type = 'TYPE',
  Updated = 'UPDATED'
}

export type Outcomemetadata = {
  __typename?: 'Outcomemetadata';
  totalCount: Scalars['Float']['output'];
};

export type PaginatedBaseCropMap = {
  __typename?: 'PaginatedBaseCropMap';
  data: Array<BaseCropMap>;
  meta: BaseCropMapmetadata;
};

export type PaginatedDriver = {
  __typename?: 'PaginatedDriver';
  data: Array<Driver>;
  meta: Drivermetadata;
};

export type PaginatedFramework = {
  __typename?: 'PaginatedFramework';
  data: Array<FrameworkEntity>;
  meta: FrameworkEntitymetadata;
};

export type PaginatedOutcome = {
  __typename?: 'PaginatedOutcome';
  data: Array<Outcome>;
  meta: Outcomemetadata;
};

export type PaginatedQuestion = {
  __typename?: 'PaginatedQuestion';
  data: Array<Question>;
  meta: Questionmetadata;
};

export type PaginatedTemplate = {
  __typename?: 'PaginatedTemplate';
  data: Array<Template>;
  meta: Templatemetadata;
};

export type PartnerEntity = {
  __typename?: 'PartnerEntity';
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  partnerFramework: Array<FrameworkPartnerEntity>;
  partner_identifier?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
};

export type PartnerLockStatusInput = {
  frameworkId: Scalars['String']['input'];
  partnerStaus?: InputMaybe<Array<PartnerStatus>>;
};

export type PartnerQuerySort = {
  order?: InputMaybe<SortOrder>;
  sortKey?: InputMaybe<PartnerSortKeys>;
};

export type PartnerSearchRequest = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Values for Partner Module Sort Keys */
export enum PartnerSortKeys {
  Created = 'CREATED',
  Id = 'ID',
  Name = 'NAME',
  Updated = 'UPDATED'
}

export type PartnerStatus = {
  partnerId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  StrengthsByDriverId: Array<Strengths>;
  additionalInformation: AdditionalInformation;
  additionalInformations: Array<AdditionalInformation>;
  auditLogs: Array<AuditLog>;
  baseCrop: BaseCropMap;
  countries: Array<Country>;
  country: Country;
  crop: Crop;
  crops: Array<Crop>;
  driver: Driver;
  drivers: PaginatedDriver;
  fetchCropsFromBase: Scalars['String']['output'];
  findAllBaseCrop: PaginatedBaseCropMap;
  framework: FrameworkEntity;
  frameworks: PaginatedFramework;
  language: Language;
  languages: Array<Language>;
  opportunities: Array<Opportunity>;
  opportunity: Opportunity;
  outcome: Outcome;
  outcomes: PaginatedOutcome;
  partner: PartnerEntity;
  partners: Array<PartnerEntity>;
  publishFramework: Scalars['String']['output'];
  question: Question;
  questions: PaginatedQuestion;
  reports: Scalars['String']['output'];
  seedUSAFramework: Scalars['String']['output'];
  strengths: Array<Strengths>;
  template: Template;
  templateFramework: TemplateFramework;
  templates: PaginatedTemplate;
  units: Array<Unit>;
  yieldUnit: YieldUnit;
  yieldUnits: Array<YieldUnit>;
};


export type QueryStrengthsByDriverIdArgs = {
  query: StrengthsByDriverIdQuerySearch;
};


export type QueryAdditionalInformationArgs = {
  id: Scalars['String']['input'];
};


export type QueryBaseCropArgs = {
  id: Scalars['String']['input'];
};


export type QueryCountriesArgs = {
  query?: InputMaybe<CountryQuerySearch>;
};


export type QueryCountryArgs = {
  id: Scalars['String']['input'];
};


export type QueryCropArgs = {
  id: Scalars['String']['input'];
};


export type QueryDriverArgs = {
  id: Scalars['Float']['input'];
};


export type QueryDriversArgs = {
  query?: InputMaybe<DriverQuerySearch>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DriverQuerySort>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchCropsFromBaseArgs = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
};


export type QueryFindAllBaseCropArgs = {
  query?: InputMaybe<BaseCropQuerySearch>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<BaseCropQuerySort>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFrameworkArgs = {
  id: Scalars['String']['input'];
};


export type QueryFrameworksArgs = {
  query?: InputMaybe<FrameworkQuerySearch>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<FrameworkQuerySort>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLanguageArgs = {
  id: Scalars['String']['input'];
};


export type QueryLanguagesArgs = {
  sort?: InputMaybe<LanguageQuerySort>;
};


export type QueryOpportunitiesArgs = {
  query?: InputMaybe<OpportunityDriverQuerySearch>;
};


export type QueryOpportunityArgs = {
  query: OpportunityQuerySearch;
};


export type QueryOutcomeArgs = {
  id: Scalars['Float']['input'];
};


export type QueryOutcomesArgs = {
  query?: InputMaybe<OutcomeQuerySearch>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<OutcomeQuerySort>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPartnerArgs = {
  id: Scalars['String']['input'];
};


export type QueryPartnersArgs = {
  sort?: InputMaybe<PartnerQuerySort>;
};


export type QueryPublishFrameworkArgs = {
  frameworkId: Scalars['String']['input'];
};


export type QueryQuestionArgs = {
  id: Scalars['String']['input'];
};


export type QueryQuestionsArgs = {
  query?: InputMaybe<QuestionQuerySearch>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<QuestionQuerySort>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReportsArgs = {
  id: Scalars['String']['input'];
};


export type QueryStrengthsArgs = {
  query?: InputMaybe<StrengthsDriverQuerySearch>;
};


export type QueryTemplateArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTemplatesArgs = {
  query?: InputMaybe<TemplateQuerySearch>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TemplateQuerySort>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryYieldUnitArgs = {
  id: Scalars['Float']['input'];
};

export type Question = {
  __typename?: 'Question';
  basecropQuestions?: Maybe<Array<BaseCropQuestionEntity>>;
  createdAt: Scalars['DateTime']['output'];
  cropQuestions?: Maybe<Array<CropQuestionEntity>>;
  drivers?: Maybe<Array<Driver>>;
  framework: FrameworkEntity;
  frameworkId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isIrrigated: Scalars['Boolean']['output'];
  isPracticeQuestion: Scalars['Boolean']['output'];
  isRainfed: Scalars['Boolean']['output'];
  labels: Array<LabelType>;
  makeDecision?: Maybe<Scalars['Boolean']['output']>;
  number: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  template?: Maybe<Template>;
  templateId?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  yield?: Maybe<YieldUnit>;
  yieldId?: Maybe<Scalars['String']['output']>;
};

export type QuestionQuerySearch = {
  drivers?: InputMaybe<Array<DriverSearchRequest>>;
  frameworkId?: InputMaybe<Scalars['String']['input']>;
  isPracticeQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  makeDecision?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  templateId?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<QuestionType>;
  yieldId?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionQuerySort = {
  order?: InputMaybe<SortOrder>;
  sortKey?: InputMaybe<QuestionSortKeys>;
};

/** various types of a skip Question */
export enum QuestionSkipType {
  Nitrogen = 'NITROGEN',
  NoTill = 'NO_TILL',
  ZeroSpecies = 'ZERO_SPECIES'
}

/** Values for Question Module Sort Keys */
export enum QuestionSortKeys {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  IsPracticeQuestion = 'IS_PRACTICE_QUESTION',
  MakeDecision = 'MAKE_DECISION',
  Number = 'NUMBER',
  Rating = 'RATING',
  Title = 'TITLE',
  UpdatedAt = 'UPDATED_AT'
}

/** various types of a Question */
export enum QuestionType {
  Condition = 'CONDITION',
  Multichoice = 'MULTICHOICE',
  Numeric = 'NUMERIC',
  Radio = 'RADIO',
  Textfield = 'TEXTFIELD'
}

export type Questionmetadata = {
  __typename?: 'Questionmetadata';
  totalCount: Scalars['Float']['output'];
};

/** Parameters of Skip in  Driver */
export type SkipObject = {
  __typename?: 'SkipObject';
  additionalAcres: AdditionalAcres;
  cropIds: Array<Scalars['String']['output']>;
  irrigationType: IrrigationType;
  isCropSpecificDriver: Scalars['Boolean']['output'];
  isPartnerSpecificDriver: Scalars['Boolean']['output'];
  partnerIds: Array<Scalars['String']['output']>;
};

export type SkipObjectInput = {
  additionalAcres?: AdditionalAcres;
  cropIds?: Array<Scalars['String']['input']>;
  irrigationType?: IrrigationType;
  isCropSpecificDriver?: Scalars['Boolean']['input'];
  isPartnerSpecificDriver?: Scalars['Boolean']['input'];
  partnerIds?: Array<Scalars['String']['input']>;
};

/** Values for Sort Order */
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** various stages of a framework */
export enum StatusType {
  Draft = 'DRAFT',
  Errored = 'ERRORED',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

export type StrengthInput = {
  credit?: InputMaybe<Scalars['Float']['input']>;
  driverId?: InputMaybe<Scalars['Float']['input']>;
  strength?: InputMaybe<Scalars['String']['input']>;
};

export type Strengths = {
  __typename?: 'Strengths';
  created: Scalars['DateTime']['output'];
  credit: Scalars['Float']['output'];
  driver: Driver;
  driverId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  strength: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type StrengthsByDriverIdQuerySearch = {
  driverId: Scalars['Float']['input'];
};

export type StrengthsDriverQuerySearch = {
  driverId?: InputMaybe<Scalars['Float']['input']>;
};

export type Template = {
  __typename?: 'Template';
  created: Scalars['DateTime']['output'];
  framework: FrameworkEntity;
  frameworkId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isMain: Scalars['Boolean']['output'];
  isMainTemplate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  outcomes: Array<Outcome>;
  updated: Scalars['DateTime']['output'];
};

export type TemplateFramework = {
  __typename?: 'TemplateFramework';
  cropYear: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isTemplateFramework: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<Question>>;
  status: Scalars['String']['output'];
  version: Scalars['String']['output'];
  versionNotes?: Maybe<Scalars['String']['output']>;
};

export type TemplateQuerySearch = {
  frameworkId?: InputMaybe<Scalars['String']['input']>;
  isMain?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateQuerySort = {
  order?: InputMaybe<SortOrder>;
  sortKey?: InputMaybe<TemplateSortKeys>;
};

export type TemplateSearchRequest = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Values for Template Module Sort Keys */
export enum TemplateSortKeys {
  Created = 'CREATED',
  Id = 'ID',
  IsMain = 'IS_MAIN',
  Name = 'NAME',
  Updated = 'UPDATED'
}

export type Templatemetadata = {
  __typename?: 'Templatemetadata';
  totalCount: Scalars['Float']['output'];
};

/** Translations of crop name */
export type TranslatedCropName = {
  __typename?: 'TranslatedCropName';
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TranslatedCropNameInput = {
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Unit = {
  __typename?: 'Unit';
  id: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UnitSearchRequest = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateBaseCropMapInput = {
  active: Scalars['Boolean']['input'];
  baseCropId: Scalars['String']['input'];
  isOtherCrop?: InputMaybe<Scalars['Boolean']['input']>;
  yieldId?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCountryInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCountryLangaugeInput = {
  country_id: Scalars['String']['input'];
  locales: Array<Scalars['String']['input']>;
};

export type UpdateCropInput = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  translatedCropNameInput?: InputMaybe<Array<TranslatedCropNameInput>>;
};

export type UpdateDriverInput = {
  context?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  outcomeIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  skip?: InputMaybe<SkipObjectInput>;
  soaidPrefix?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFrameworkInput = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  cropIds?: InputMaybe<Array<Scalars['String']['input']>>;
  cropYear?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  lockedPartnerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerIdsWithUserIdCheck?: InputMaybe<Array<Scalars['String']['input']>>;
  partners?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Scalars['String']['input']>;
  unitId?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  versionNotes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFrameworkPartnerInput = {
  frameworkId: Scalars['String']['input'];
  frameworkPartners: Array<FrameworkPartnerUpdate>;
};

export type UpdateFrameworkStrengthsInput = {
  framework_id: Scalars['String']['input'];
};

export type UpdateOpportunityByDriverNumberInput = {
  driverNumber: Scalars['String']['input'];
  frameworkId: Array<Scalars['String']['input']>;
  opportunityInput: Array<OpportunityInput>;
};

export type UpdateOpportunityInput = {
  credit?: InputMaybe<Scalars['Float']['input']>;
  driverId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
  recommendation: Scalars['String']['input'];
};

export type UpdateOutcomeInput = {
  frameworkId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  templates?: InputMaybe<Array<Scalars['Float']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartnerInput = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateQuestionForMultipleFrameworksInput = {
  additionalInformation?: InputMaybe<AdditionalInformationInput>;
  enableTranslation?: Scalars['Boolean']['input'];
  frameworkId?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<LabelTypeInput>>;
  number: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  updatenumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateQuestionInput = {
  cropQuestions?: InputMaybe<Array<CropQuestionInput>>;
  driverIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  id: Scalars['String']['input'];
  isPracticeQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<LabelTypeInput>>;
  makeDecision?: InputMaybe<Scalars['Boolean']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  templateId?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateQuestionOpportunityStrengthInput = {
  context?: InputMaybe<Scalars['String']['input']>;
  driverId: Scalars['Float']['input'];
  updateOpportunities: Array<UpdateOpportunityInput>;
  updateStrengths: Array<UpdateStrengthsInput>;
  updateYesNoQuestionInput: Array<UpdateYesNoQuestionInput>;
};

export type UpdateStrengthByDriverNumberInput = {
  driverNumber: Scalars['String']['input'];
  frameworkId: Array<Scalars['String']['input']>;
  strengthInput: Array<StrengthInput>;
};

export type UpdateStrengthsInput = {
  credit?: InputMaybe<Scalars['Float']['input']>;
  driverId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
  strength: Scalars['String']['input'];
};

export type UpdateTemplateInput = {
  frameworkId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateYesNoQuestionInput = {
  id: Scalars['String']['input'];
  isPracticeQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<LabelTypeInput>>;
  makeDecision?: InputMaybe<Scalars['Boolean']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateYesNoQuestionOutput = {
  __typename?: 'UpdateYesNoQuestionOutput';
  driver?: Maybe<Driver>;
  updateOpportunities?: Maybe<Array<Opportunity>>;
  updateQuestions?: Maybe<Array<Question>>;
  updateStrengths?: Maybe<Array<Strengths>>;
};

export type UpdateYieldUnitInput = {
  id: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type ValidateDriverInput = {
  frameworkId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  soaidPrefix?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateDriverOutput = {
  __typename?: 'ValidateDriverOutput';
  isValid: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  soaidPrefix?: Maybe<Scalars['String']['output']>;
};

export type YieldUnit = {
  __typename?: 'YieldUnit';
  created: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type AllCountriesQueryVariables = Exact<{
  query?: InputMaybe<CountryQuerySearch>;
}>;


export type AllCountriesQuery = { __typename?: 'Query', countries: Array<{ __typename?: 'Country', id: string, name: string, code: string, isActive: boolean, countriesLanguages?: Array<{ __typename?: 'CountryToLanguage', language: { __typename?: 'Language', locale: string, name: string, id: string } }> | null }> };

export type UpdateCountryMutationVariables = Exact<{
  updateCountryInput: UpdateCountryInput;
}>;


export type UpdateCountryMutation = { __typename?: 'Mutation', updateCountry: { __typename?: 'Country', isActive: boolean, id: string } };

export type FindAllBaseCropQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<BaseCropQuerySearch>;
  sort?: InputMaybe<BaseCropQuerySort>;
}>;


export type FindAllBaseCropQuery = { __typename?: 'Query', findAllBaseCrop: { __typename?: 'PaginatedBaseCropMap', data: Array<{ __typename?: 'BaseCropMap', name: string, baseCropId: string, active: boolean, yield?: { __typename?: 'YieldUnit', title: string, id: number } | null, language: { __typename?: 'Language', locale: string } }>, meta: { __typename?: 'BaseCropMapmetadata', totalCount: number } } };

export type FetchCropsFromBaseQueryVariables = Exact<{
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
}>;


export type FetchCropsFromBaseQuery = { __typename?: 'Query', fetchCropsFromBase: string };

export type UpdateBaseCropMutationVariables = Exact<{
  updateBaseCropMapInput: UpdateBaseCropMapInput;
}>;


export type UpdateBaseCropMutation = { __typename?: 'Mutation', updateBaseCropMap: { __typename?: 'BaseCropMap', name: string, active: boolean } };

export type YieldUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type YieldUnitsQuery = { __typename?: 'Query', yieldUnits: Array<{ __typename?: 'YieldUnit', id: number, title: string }> };

export type CreateDriverMutationVariables = Exact<{
  createDriverInput: CreateDriverInput;
}>;


export type CreateDriverMutation = { __typename?: 'Mutation', createDriver: { __typename?: 'Driver', id: number } };

export type DriverQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type DriverQuery = { __typename?: 'Query', driver: { __typename?: 'Driver', id: number, name: string, context?: string | null, soaidPrefix?: string | null, number: string, isAvailableInMainTemplate: boolean, outcomes?: Array<{ __typename?: 'Outcome', id: number, name: string }> | null, questions: Array<{ __typename?: 'Question', title?: string | null, rating?: number | null, id: string, number: string, labels: Array<{ __typename?: 'LabelType', soa_id?: string | null }> }>, skip?: { __typename?: 'SkipObject', partnerIds: Array<string>, cropIds: Array<string>, irrigationType: IrrigationType, additionalAcres: AdditionalAcres, isCropSpecificDriver: boolean, isPartnerSpecificDriver: boolean } | null } };

export type DriversQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<DriverQuerySearch>;
  sort?: InputMaybe<DriverQuerySort>;
}>;


export type DriversQuery = { __typename?: 'Query', drivers: { __typename?: 'PaginatedDriver', data: Array<{ __typename?: 'Driver', id: number, name: string, questionCount: number, number: string, skip?: { __typename?: 'SkipObject', partnerIds: Array<string>, cropIds: Array<string>, irrigationType: IrrigationType, additionalAcres: AdditionalAcres } | null }>, meta: { __typename?: 'Drivermetadata', totalCount: number } } };

export type RemoveDriverMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type RemoveDriverMutation = { __typename?: 'Mutation', removeDriver: string };

export type UpdateDriverDetailsMutationVariables = Exact<{
  updateDriverInput: UpdateDriverInput;
}>;


export type UpdateDriverDetailsMutation = { __typename?: 'Mutation', updateDriver: { __typename?: 'Driver', name: string, soaidPrefix?: string | null, context?: string | null, outcomes?: Array<{ __typename?: 'Outcome', id: number, name: string }> | null, skip?: { __typename?: 'SkipObject', partnerIds: Array<string>, cropIds: Array<string>, irrigationType: IrrigationType, additionalAcres: AdditionalAcres } | null } };

export type ValidateDriverMutationVariables = Exact<{
  validateCreateDriver: ValidateDriverInput;
}>;


export type ValidateDriverMutation = { __typename?: 'Mutation', validateDriver: { __typename?: 'ValidateDriverOutput', isValid: boolean, name: string, soaidPrefix?: string | null } };

export type CloneFrameworkMutationVariables = Exact<{
  cloneFrameworkInput: CloneFrameworkInput;
}>;


export type CloneFrameworkMutation = { __typename?: 'Mutation', cloneFramework: { __typename?: 'FrameworkEntity', id: string } };

export type CreateFrameworkMutationVariables = Exact<{
  createFrameworkInput: CreateFrameworkInput;
}>;


export type CreateFrameworkMutation = { __typename?: 'Mutation', createFramework: { __typename?: 'FrameworkEntity', id: string, name?: string | null, version: string } };

export type AllCropsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCropsQuery = { __typename?: 'Query', crops: Array<{ __typename?: 'Crop', name: string, id: string, translatedCropName: Array<{ __typename?: 'TranslatedCropName', name: string, locale: string }> }> };

export type FrameworkQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FrameworkQuery = { __typename?: 'Query', framework: { __typename?: 'FrameworkEntity', id: string, version: string, name?: string | null, cropYear: string, status: string, unitId: string, unit: { __typename?: 'Unit', value: string }, country: { __typename?: 'Country', name: string, id: string }, frameworkPartner?: Array<{ __typename?: 'FrameworkPartnerEntity', status: string, partnerId: string, is_unique_identifier_required: boolean, partner: { __typename?: 'PartnerEntity', name: string, id: string } }> | null, languages?: Array<{ __typename?: 'Language', id: string, name: string, locale: string }> | null, baseCrops?: Array<{ __typename?: 'BaseCropMap', baseCropId: string }> | null } };

export type FrameworksQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<FrameworkQuerySearch>;
}>;


export type FrameworksQuery = { __typename?: 'Query', frameworks: { __typename?: 'PaginatedFramework', data: Array<{ __typename?: 'FrameworkEntity', id: string, name?: string | null, cropYear: string, status: string, version: string, isActive: boolean, countryId: string, country: { __typename?: 'Country', name: string } }>, meta: { __typename?: 'FrameworkEntitymetadata', totalCount: number } } };

export type RemoveFrameworkMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RemoveFrameworkMutation = { __typename?: 'Mutation', removeFramework: { __typename?: 'FrameworkEntity', id: string } };

export type UnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitsQuery = { __typename?: 'Query', units: Array<{ __typename?: 'Unit', id: string, value: string }> };

export type UpdateFrameworkMutationVariables = Exact<{
  updateFrameworkInput: UpdateFrameworkInput;
}>;


export type UpdateFrameworkMutation = { __typename?: 'Mutation', updateFramework: { __typename?: 'FrameworkEntity', id: string, status: string, frameworkPartner?: Array<{ __typename?: 'FrameworkPartnerEntity', partnerId: string, status: string }> | null } };

export type CreateOutcomeMutationVariables = Exact<{
  createOutcomeInput: CreateOutcomeInput;
}>;


export type CreateOutcomeMutation = { __typename?: 'Mutation', createOutcome: { __typename?: 'Outcome', id: number } };

export type RemoveOutcomeMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type RemoveOutcomeMutation = { __typename?: 'Mutation', removeOutcome: string };

export type OutcomeQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type OutcomeQuery = { __typename?: 'Query', outcome: { __typename?: 'Outcome', id: number, name: string, type: string, number: string, isAvailableInMainTemplate: boolean } };

export type OutcomesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<OutcomeQuerySearch>;
  sort?: InputMaybe<OutcomeQuerySort>;
}>;


export type OutcomesQuery = { __typename?: 'Query', outcomes: { __typename?: 'PaginatedOutcome', data: Array<{ __typename?: 'Outcome', id: number, name: string, number: string, driverCount: number, created: any, type: string }>, meta: { __typename?: 'Outcomemetadata', totalCount: number } } };

export type UpdateOutcomeMutationVariables = Exact<{
  updateOutcomeInput: UpdateOutcomeInput;
}>;


export type UpdateOutcomeMutation = { __typename?: 'Mutation', updateOutcome: { __typename?: 'Outcome', id: number, name: string } };

export type CreateQuestionMutationVariables = Exact<{
  input: CreateQuestionInput;
}>;


export type CreateQuestionMutation = { __typename?: 'Mutation', createQuestion: { __typename?: 'Question', type: string, title?: string | null, id: string, number: string } };

export type CreateQuestionsOpportunitiesAndStrengthsMutationVariables = Exact<{
  createYesNoQuestions: CreateQuestionOpportunityAndStrengthInput;
}>;


export type CreateQuestionsOpportunitiesAndStrengthsMutation = { __typename?: 'Mutation', createQuestionsOpportunitiesAndStrengths: { __typename?: 'CreateYesNoQuestionOutput', createOpportunities: Array<{ __typename?: 'Opportunity', id: number }>, createQuestions?: Array<{ __typename?: 'Question', id: string }> | null, driver?: { __typename?: 'Driver', name: string, id: number, number: string } | null } };

export type OpportunitiesQueryVariables = Exact<{
  query?: InputMaybe<OpportunityDriverQuerySearch>;
}>;


export type OpportunitiesQuery = { __typename?: 'Query', opportunities: Array<{ __typename?: 'Opportunity', id: number, credit: number, recommendation: string }> };

export type OpportunityQueryVariables = Exact<{
  query: OpportunityQuerySearch;
}>;


export type OpportunityQuery = { __typename?: 'Query', opportunity: { __typename?: 'Opportunity', id: number, recommendation: string } };

export type QuestionQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type QuestionQuery = { __typename?: 'Query', question: { __typename?: 'Question', type: string, title?: string | null, labels: Array<{ __typename?: 'LabelType', labelId?: number | null, labelName?: string | null, soa_id?: string | null, unit?: string | null, answer?: string | null, relatedReportField?: string | null }> } };

export type QuestionsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<QuestionQuerySearch>;
  sort?: InputMaybe<QuestionQuerySort>;
}>;


export type QuestionsQuery = { __typename?: 'Query', questions: { __typename?: 'PaginatedQuestion', data: Array<{ __typename?: 'Question', id: string, title?: string | null, type: string, number: string, isActive: boolean }>, meta: { __typename?: 'Questionmetadata', totalCount: number } } };

export type RemoveOpportunityMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RemoveOpportunityMutation = { __typename?: 'Mutation', removeOpportunity: string };

export type RemoveQuestionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RemoveQuestionMutation = { __typename?: 'Mutation', removeQuestion: string };

export type DeleteyesnoMutationVariables = Exact<{
  driverId: Scalars['Float']['input'];
}>;


export type DeleteyesnoMutation = { __typename?: 'Mutation', removeQuestionsAndOpportunities: string };

export type StrengthsByDriverIdQueryVariables = Exact<{
  query: StrengthsByDriverIdQuerySearch;
}>;


export type StrengthsByDriverIdQuery = { __typename?: 'Query', StrengthsByDriverId: Array<{ __typename?: 'Strengths', strength: string, id: number, credit: number }> };

export type UpdateQuestionMutationVariables = Exact<{
  updateQuestionInput: UpdateQuestionInput;
}>;


export type UpdateQuestionMutation = { __typename?: 'Mutation', updateQuestion: { __typename?: 'Question', id: string, title?: string | null, type: string } };

export type UpdateQuestionsOpportunitiesAndStrengthsMutationVariables = Exact<{
  updateYesNoQuestions: UpdateQuestionOpportunityStrengthInput;
}>;


export type UpdateQuestionsOpportunitiesAndStrengthsMutation = { __typename?: 'Mutation', updateQuestionsOpportunitiesAndStrengths: { __typename?: 'UpdateYesNoQuestionOutput', driver?: { __typename?: 'Driver', id: number } | null } };

export type SaveMainTemplateMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SaveMainTemplateMutation = { __typename?: 'Mutation', saveMainTemplate: string };

export type TemplateQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type TemplateQuery = { __typename?: 'Query', template: { __typename?: 'Template', id: number, name: string } };

export type TemplatesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<TemplateQuerySearch>;
  sort?: InputMaybe<TemplateQuerySort>;
}>;


export type TemplatesQuery = { __typename?: 'Query', templates: { __typename?: 'PaginatedTemplate', data: Array<{ __typename?: 'Template', id: number, name: string, isMain: boolean, isMainTemplate: boolean }>, meta: { __typename?: 'Templatemetadata', totalCount: number } } };

export type AllLanguagesQueryVariables = Exact<{
  sort?: InputMaybe<LanguageQuerySort>;
}>;


export type AllLanguagesQuery = { __typename?: 'Query', languages: Array<{ __typename?: 'Language', id: string, name: string, locale: string, shortLabel: string }> };

export type CreatePartnerMutationVariables = Exact<{
  createPartnerInput: CreatePartnerInput;
}>;


export type CreatePartnerMutation = { __typename?: 'Mutation', createPartner: { __typename?: 'PartnerEntity', id: string } };

export type AllPartnersQueryVariables = Exact<{
  sort?: InputMaybe<PartnerQuerySort>;
}>;


export type AllPartnersQuery = { __typename?: 'Query', partners: Array<{ __typename?: 'PartnerEntity', name: string, id: string, created: any, updated: any }> };

export type RemovePartnerMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RemovePartnerMutation = { __typename?: 'Mutation', removePartner: string };

export type ReportDataQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReportDataQuery = { __typename?: 'Query', reports: string };



export const AllCountriesDocument = `
    query allCountries($query: CountryQuerySearch) {
  countries(query: $query) {
    id
    name
    code
    isActive
    countriesLanguages {
      language {
        locale
        name
        id
      }
    }
  }
}
    `;

export const useAllCountriesQuery = <
      TData = AllCountriesQuery,
      TError = unknown
    >(
      variables?: AllCountriesQueryVariables,
      options?: UseQueryOptions<AllCountriesQuery, TError, TData>
    ) => {
    
    return useQuery<AllCountriesQuery, TError, TData>(
      variables === undefined ? ['allCountries'] : ['allCountries', variables],
      ferry<AllCountriesQuery, AllCountriesQueryVariables>(AllCountriesDocument, variables),
      options
    )};

export const useInfiniteAllCountriesQuery = <
      TData = AllCountriesQuery,
      TError = unknown
    >(
      variables?: AllCountriesQueryVariables,
      options?: UseInfiniteQueryOptions<AllCountriesQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<AllCountriesQuery, TError, TData>(
      variables === undefined ? ['allCountries.infinite'] : ['allCountries.infinite', variables],
      (metaData) => ferry<AllCountriesQuery, AllCountriesQueryVariables>(AllCountriesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const UpdateCountryDocument = `
    mutation updateCountry($updateCountryInput: UpdateCountryInput!) {
  updateCountry(updateCountryInput: $updateCountryInput) {
    isActive
    id
  }
}
    `;

export const useUpdateCountryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCountryMutation, TError, UpdateCountryMutationVariables, TContext>) => {
    
    return useMutation<UpdateCountryMutation, TError, UpdateCountryMutationVariables, TContext>(
      ['updateCountry'],
      (variables?: UpdateCountryMutationVariables) => ferry<UpdateCountryMutation, UpdateCountryMutationVariables>(UpdateCountryDocument, variables)(),
      options
    )};

export const FindAllBaseCropDocument = `
    query findAllBaseCrop($skip: Int, $take: Int, $query: BaseCropQuerySearch, $sort: BaseCropQuerySort) {
  findAllBaseCrop(skip: $skip, take: $take, query: $query, sort: $sort) {
    data {
      name
      baseCropId
      yield {
        title
        id
      }
      active
      language {
        locale
      }
    }
    meta {
      totalCount
    }
  }
}
    `;

export const useFindAllBaseCropQuery = <
      TData = FindAllBaseCropQuery,
      TError = unknown
    >(
      variables?: FindAllBaseCropQueryVariables,
      options?: UseQueryOptions<FindAllBaseCropQuery, TError, TData>
    ) => {
    
    return useQuery<FindAllBaseCropQuery, TError, TData>(
      variables === undefined ? ['findAllBaseCrop'] : ['findAllBaseCrop', variables],
      ferry<FindAllBaseCropQuery, FindAllBaseCropQueryVariables>(FindAllBaseCropDocument, variables),
      options
    )};

export const useInfiniteFindAllBaseCropQuery = <
      TData = FindAllBaseCropQuery,
      TError = unknown
    >(
      variables?: FindAllBaseCropQueryVariables,
      options?: UseInfiniteQueryOptions<FindAllBaseCropQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<FindAllBaseCropQuery, TError, TData>(
      variables === undefined ? ['findAllBaseCrop.infinite'] : ['findAllBaseCrop.infinite', variables],
      (metaData) => ferry<FindAllBaseCropQuery, FindAllBaseCropQueryVariables>(FindAllBaseCropDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const FetchCropsFromBaseDocument = `
    query fetchCropsFromBase($countryCode: String!, $languageCode: String!) {
  fetchCropsFromBase(countryCode: $countryCode, languageCode: $languageCode)
}
    `;

export const useFetchCropsFromBaseQuery = <
      TData = FetchCropsFromBaseQuery,
      TError = unknown
    >(
      variables: FetchCropsFromBaseQueryVariables,
      options?: UseQueryOptions<FetchCropsFromBaseQuery, TError, TData>
    ) => {
    
    return useQuery<FetchCropsFromBaseQuery, TError, TData>(
      ['fetchCropsFromBase', variables],
      ferry<FetchCropsFromBaseQuery, FetchCropsFromBaseQueryVariables>(FetchCropsFromBaseDocument, variables),
      options
    )};

export const useInfiniteFetchCropsFromBaseQuery = <
      TData = FetchCropsFromBaseQuery,
      TError = unknown
    >(
      variables: FetchCropsFromBaseQueryVariables,
      options?: UseInfiniteQueryOptions<FetchCropsFromBaseQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<FetchCropsFromBaseQuery, TError, TData>(
      ['fetchCropsFromBase.infinite', variables],
      (metaData) => ferry<FetchCropsFromBaseQuery, FetchCropsFromBaseQueryVariables>(FetchCropsFromBaseDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const UpdateBaseCropDocument = `
    mutation updateBaseCrop($updateBaseCropMapInput: UpdateBaseCropMapInput!) {
  updateBaseCropMap(updateBaseCropMapInput: $updateBaseCropMapInput) {
    name
    active
  }
}
    `;

export const useUpdateBaseCropMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBaseCropMutation, TError, UpdateBaseCropMutationVariables, TContext>) => {
    
    return useMutation<UpdateBaseCropMutation, TError, UpdateBaseCropMutationVariables, TContext>(
      ['updateBaseCrop'],
      (variables?: UpdateBaseCropMutationVariables) => ferry<UpdateBaseCropMutation, UpdateBaseCropMutationVariables>(UpdateBaseCropDocument, variables)(),
      options
    )};

export const YieldUnitsDocument = `
    query yieldUnits {
  yieldUnits {
    id
    title
  }
}
    `;

export const useYieldUnitsQuery = <
      TData = YieldUnitsQuery,
      TError = unknown
    >(
      variables?: YieldUnitsQueryVariables,
      options?: UseQueryOptions<YieldUnitsQuery, TError, TData>
    ) => {
    
    return useQuery<YieldUnitsQuery, TError, TData>(
      variables === undefined ? ['yieldUnits'] : ['yieldUnits', variables],
      ferry<YieldUnitsQuery, YieldUnitsQueryVariables>(YieldUnitsDocument, variables),
      options
    )};

export const useInfiniteYieldUnitsQuery = <
      TData = YieldUnitsQuery,
      TError = unknown
    >(
      variables?: YieldUnitsQueryVariables,
      options?: UseInfiniteQueryOptions<YieldUnitsQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<YieldUnitsQuery, TError, TData>(
      variables === undefined ? ['yieldUnits.infinite'] : ['yieldUnits.infinite', variables],
      (metaData) => ferry<YieldUnitsQuery, YieldUnitsQueryVariables>(YieldUnitsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const CreateDriverDocument = `
    mutation createDriver($createDriverInput: CreateDriverInput!) {
  createDriver(createDriverInput: $createDriverInput) {
    id
  }
}
    `;

export const useCreateDriverMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDriverMutation, TError, CreateDriverMutationVariables, TContext>) => {
    
    return useMutation<CreateDriverMutation, TError, CreateDriverMutationVariables, TContext>(
      ['createDriver'],
      (variables?: CreateDriverMutationVariables) => ferry<CreateDriverMutation, CreateDriverMutationVariables>(CreateDriverDocument, variables)(),
      options
    )};

export const DriverDocument = `
    query driver($id: Float!) {
  driver(id: $id) {
    id
    name
    context
    soaidPrefix
    outcomes {
      id
      name
    }
    number
    isAvailableInMainTemplate
    questions {
      title
      rating
      id
      number
      labels {
        soa_id
      }
    }
    skip {
      partnerIds
      cropIds
      irrigationType
      additionalAcres
      isCropSpecificDriver
      isPartnerSpecificDriver
    }
  }
}
    `;

export const useDriverQuery = <
      TData = DriverQuery,
      TError = unknown
    >(
      variables: DriverQueryVariables,
      options?: UseQueryOptions<DriverQuery, TError, TData>
    ) => {
    
    return useQuery<DriverQuery, TError, TData>(
      ['driver', variables],
      ferry<DriverQuery, DriverQueryVariables>(DriverDocument, variables),
      options
    )};

export const useInfiniteDriverQuery = <
      TData = DriverQuery,
      TError = unknown
    >(
      variables: DriverQueryVariables,
      options?: UseInfiniteQueryOptions<DriverQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<DriverQuery, TError, TData>(
      ['driver.infinite', variables],
      (metaData) => ferry<DriverQuery, DriverQueryVariables>(DriverDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const DriversDocument = `
    query drivers($skip: Int, $take: Int, $query: DriverQuerySearch, $sort: DriverQuerySort) {
  drivers(skip: $skip, take: $take, query: $query, sort: $sort) {
    data {
      id
      name
      questionCount
      number
      skip {
        partnerIds
        cropIds
        irrigationType
        additionalAcres
      }
    }
    meta {
      totalCount
    }
  }
}
    `;

export const useDriversQuery = <
      TData = DriversQuery,
      TError = unknown
    >(
      variables?: DriversQueryVariables,
      options?: UseQueryOptions<DriversQuery, TError, TData>
    ) => {
    
    return useQuery<DriversQuery, TError, TData>(
      variables === undefined ? ['drivers'] : ['drivers', variables],
      ferry<DriversQuery, DriversQueryVariables>(DriversDocument, variables),
      options
    )};

export const useInfiniteDriversQuery = <
      TData = DriversQuery,
      TError = unknown
    >(
      variables?: DriversQueryVariables,
      options?: UseInfiniteQueryOptions<DriversQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<DriversQuery, TError, TData>(
      variables === undefined ? ['drivers.infinite'] : ['drivers.infinite', variables],
      (metaData) => ferry<DriversQuery, DriversQueryVariables>(DriversDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const RemoveDriverDocument = `
    mutation removeDriver($id: Float!) {
  removeDriver(id: $id)
}
    `;

export const useRemoveDriverMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveDriverMutation, TError, RemoveDriverMutationVariables, TContext>) => {
    
    return useMutation<RemoveDriverMutation, TError, RemoveDriverMutationVariables, TContext>(
      ['removeDriver'],
      (variables?: RemoveDriverMutationVariables) => ferry<RemoveDriverMutation, RemoveDriverMutationVariables>(RemoveDriverDocument, variables)(),
      options
    )};

export const UpdateDriverDetailsDocument = `
    mutation updateDriverDetails($updateDriverInput: UpdateDriverInput!) {
  updateDriver(updateDriverInput: $updateDriverInput) {
    name
    soaidPrefix
    context
    outcomes {
      id
      name
    }
    skip {
      partnerIds
      cropIds
      irrigationType
      additionalAcres
    }
  }
}
    `;

export const useUpdateDriverDetailsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateDriverDetailsMutation, TError, UpdateDriverDetailsMutationVariables, TContext>) => {
    
    return useMutation<UpdateDriverDetailsMutation, TError, UpdateDriverDetailsMutationVariables, TContext>(
      ['updateDriverDetails'],
      (variables?: UpdateDriverDetailsMutationVariables) => ferry<UpdateDriverDetailsMutation, UpdateDriverDetailsMutationVariables>(UpdateDriverDetailsDocument, variables)(),
      options
    )};

export const ValidateDriverDocument = `
    mutation validateDriver($validateCreateDriver: ValidateDriverInput!) {
  validateDriver(validateCreateDriver: $validateCreateDriver) {
    isValid
    name
    soaidPrefix
  }
}
    `;

export const useValidateDriverMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ValidateDriverMutation, TError, ValidateDriverMutationVariables, TContext>) => {
    
    return useMutation<ValidateDriverMutation, TError, ValidateDriverMutationVariables, TContext>(
      ['validateDriver'],
      (variables?: ValidateDriverMutationVariables) => ferry<ValidateDriverMutation, ValidateDriverMutationVariables>(ValidateDriverDocument, variables)(),
      options
    )};

export const CloneFrameworkDocument = `
    mutation cloneFramework($cloneFrameworkInput: CloneFrameworkInput!) {
  cloneFramework(cloneFrameworkInput: $cloneFrameworkInput) {
    id
  }
}
    `;

export const useCloneFrameworkMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CloneFrameworkMutation, TError, CloneFrameworkMutationVariables, TContext>) => {
    
    return useMutation<CloneFrameworkMutation, TError, CloneFrameworkMutationVariables, TContext>(
      ['cloneFramework'],
      (variables?: CloneFrameworkMutationVariables) => ferry<CloneFrameworkMutation, CloneFrameworkMutationVariables>(CloneFrameworkDocument, variables)(),
      options
    )};

export const CreateFrameworkDocument = `
    mutation createFramework($createFrameworkInput: CreateFrameworkInput!) {
  createFramework(createFrameworkInput: $createFrameworkInput) {
    id
    name
    version
  }
}
    `;

export const useCreateFrameworkMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateFrameworkMutation, TError, CreateFrameworkMutationVariables, TContext>) => {
    
    return useMutation<CreateFrameworkMutation, TError, CreateFrameworkMutationVariables, TContext>(
      ['createFramework'],
      (variables?: CreateFrameworkMutationVariables) => ferry<CreateFrameworkMutation, CreateFrameworkMutationVariables>(CreateFrameworkDocument, variables)(),
      options
    )};

export const AllCropsDocument = `
    query allCrops {
  crops {
    name
    id
    translatedCropName {
      name
      locale
    }
  }
}
    `;

export const useAllCropsQuery = <
      TData = AllCropsQuery,
      TError = unknown
    >(
      variables?: AllCropsQueryVariables,
      options?: UseQueryOptions<AllCropsQuery, TError, TData>
    ) => {
    
    return useQuery<AllCropsQuery, TError, TData>(
      variables === undefined ? ['allCrops'] : ['allCrops', variables],
      ferry<AllCropsQuery, AllCropsQueryVariables>(AllCropsDocument, variables),
      options
    )};

export const useInfiniteAllCropsQuery = <
      TData = AllCropsQuery,
      TError = unknown
    >(
      variables?: AllCropsQueryVariables,
      options?: UseInfiniteQueryOptions<AllCropsQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<AllCropsQuery, TError, TData>(
      variables === undefined ? ['allCrops.infinite'] : ['allCrops.infinite', variables],
      (metaData) => ferry<AllCropsQuery, AllCropsQueryVariables>(AllCropsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const FrameworkDocument = `
    query framework($id: String!) {
  framework(id: $id) {
    id
    version
    name
    cropYear
    status
    unitId
    unit {
      value
    }
    country {
      name
      id
    }
    frameworkPartner {
      partner {
        name
        id
      }
      status
      partnerId
      is_unique_identifier_required
    }
    languages {
      id
      name
      locale
    }
    baseCrops {
      baseCropId
    }
  }
}
    `;

export const useFrameworkQuery = <
      TData = FrameworkQuery,
      TError = unknown
    >(
      variables: FrameworkQueryVariables,
      options?: UseQueryOptions<FrameworkQuery, TError, TData>
    ) => {
    
    return useQuery<FrameworkQuery, TError, TData>(
      ['framework', variables],
      ferry<FrameworkQuery, FrameworkQueryVariables>(FrameworkDocument, variables),
      options
    )};

export const useInfiniteFrameworkQuery = <
      TData = FrameworkQuery,
      TError = unknown
    >(
      variables: FrameworkQueryVariables,
      options?: UseInfiniteQueryOptions<FrameworkQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<FrameworkQuery, TError, TData>(
      ['framework.infinite', variables],
      (metaData) => ferry<FrameworkQuery, FrameworkQueryVariables>(FrameworkDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const FrameworksDocument = `
    query frameworks($skip: Int, $take: Int, $query: FrameworkQuerySearch) {
  frameworks(skip: $skip, take: $take, query: $query) {
    data {
      id
      name
      cropYear
      status
      version
      isActive
      countryId
      country {
        name
      }
    }
    meta {
      totalCount
    }
  }
}
    `;

export const useFrameworksQuery = <
      TData = FrameworksQuery,
      TError = unknown
    >(
      variables?: FrameworksQueryVariables,
      options?: UseQueryOptions<FrameworksQuery, TError, TData>
    ) => {
    
    return useQuery<FrameworksQuery, TError, TData>(
      variables === undefined ? ['frameworks'] : ['frameworks', variables],
      ferry<FrameworksQuery, FrameworksQueryVariables>(FrameworksDocument, variables),
      options
    )};

export const useInfiniteFrameworksQuery = <
      TData = FrameworksQuery,
      TError = unknown
    >(
      variables?: FrameworksQueryVariables,
      options?: UseInfiniteQueryOptions<FrameworksQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<FrameworksQuery, TError, TData>(
      variables === undefined ? ['frameworks.infinite'] : ['frameworks.infinite', variables],
      (metaData) => ferry<FrameworksQuery, FrameworksQueryVariables>(FrameworksDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const RemoveFrameworkDocument = `
    mutation removeFramework($id: String!) {
  removeFramework(id: $id) {
    id
  }
}
    `;

export const useRemoveFrameworkMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveFrameworkMutation, TError, RemoveFrameworkMutationVariables, TContext>) => {
    
    return useMutation<RemoveFrameworkMutation, TError, RemoveFrameworkMutationVariables, TContext>(
      ['removeFramework'],
      (variables?: RemoveFrameworkMutationVariables) => ferry<RemoveFrameworkMutation, RemoveFrameworkMutationVariables>(RemoveFrameworkDocument, variables)(),
      options
    )};

export const UnitsDocument = `
    query units {
  units {
    id
    value
  }
}
    `;

export const useUnitsQuery = <
      TData = UnitsQuery,
      TError = unknown
    >(
      variables?: UnitsQueryVariables,
      options?: UseQueryOptions<UnitsQuery, TError, TData>
    ) => {
    
    return useQuery<UnitsQuery, TError, TData>(
      variables === undefined ? ['units'] : ['units', variables],
      ferry<UnitsQuery, UnitsQueryVariables>(UnitsDocument, variables),
      options
    )};

export const useInfiniteUnitsQuery = <
      TData = UnitsQuery,
      TError = unknown
    >(
      variables?: UnitsQueryVariables,
      options?: UseInfiniteQueryOptions<UnitsQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<UnitsQuery, TError, TData>(
      variables === undefined ? ['units.infinite'] : ['units.infinite', variables],
      (metaData) => ferry<UnitsQuery, UnitsQueryVariables>(UnitsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const UpdateFrameworkDocument = `
    mutation updateFramework($updateFrameworkInput: UpdateFrameworkInput!) {
  updateFramework(updateFrameworkInput: $updateFrameworkInput) {
    id
    status
    frameworkPartner {
      partnerId
      status
    }
  }
}
    `;

export const useUpdateFrameworkMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateFrameworkMutation, TError, UpdateFrameworkMutationVariables, TContext>) => {
    
    return useMutation<UpdateFrameworkMutation, TError, UpdateFrameworkMutationVariables, TContext>(
      ['updateFramework'],
      (variables?: UpdateFrameworkMutationVariables) => ferry<UpdateFrameworkMutation, UpdateFrameworkMutationVariables>(UpdateFrameworkDocument, variables)(),
      options
    )};

export const CreateOutcomeDocument = `
    mutation createOutcome($createOutcomeInput: CreateOutcomeInput!) {
  createOutcome(createOutcomeInput: $createOutcomeInput) {
    id
  }
}
    `;

export const useCreateOutcomeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOutcomeMutation, TError, CreateOutcomeMutationVariables, TContext>) => {
    
    return useMutation<CreateOutcomeMutation, TError, CreateOutcomeMutationVariables, TContext>(
      ['createOutcome'],
      (variables?: CreateOutcomeMutationVariables) => ferry<CreateOutcomeMutation, CreateOutcomeMutationVariables>(CreateOutcomeDocument, variables)(),
      options
    )};

export const RemoveOutcomeDocument = `
    mutation removeOutcome($id: Float!) {
  removeOutcome(id: $id)
}
    `;

export const useRemoveOutcomeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveOutcomeMutation, TError, RemoveOutcomeMutationVariables, TContext>) => {
    
    return useMutation<RemoveOutcomeMutation, TError, RemoveOutcomeMutationVariables, TContext>(
      ['removeOutcome'],
      (variables?: RemoveOutcomeMutationVariables) => ferry<RemoveOutcomeMutation, RemoveOutcomeMutationVariables>(RemoveOutcomeDocument, variables)(),
      options
    )};

export const OutcomeDocument = `
    query outcome($id: Float!) {
  outcome(id: $id) {
    id
    name
    type
    number
    isAvailableInMainTemplate
  }
}
    `;

export const useOutcomeQuery = <
      TData = OutcomeQuery,
      TError = unknown
    >(
      variables: OutcomeQueryVariables,
      options?: UseQueryOptions<OutcomeQuery, TError, TData>
    ) => {
    
    return useQuery<OutcomeQuery, TError, TData>(
      ['outcome', variables],
      ferry<OutcomeQuery, OutcomeQueryVariables>(OutcomeDocument, variables),
      options
    )};

export const useInfiniteOutcomeQuery = <
      TData = OutcomeQuery,
      TError = unknown
    >(
      variables: OutcomeQueryVariables,
      options?: UseInfiniteQueryOptions<OutcomeQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<OutcomeQuery, TError, TData>(
      ['outcome.infinite', variables],
      (metaData) => ferry<OutcomeQuery, OutcomeQueryVariables>(OutcomeDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const OutcomesDocument = `
    query outcomes($skip: Int, $take: Int, $query: OutcomeQuerySearch, $sort: OutcomeQuerySort) {
  outcomes(skip: $skip, take: $take, query: $query, sort: $sort) {
    data {
      id
      name
      number
      driverCount
      created
      number
      type
    }
    meta {
      totalCount
    }
  }
}
    `;

export const useOutcomesQuery = <
      TData = OutcomesQuery,
      TError = unknown
    >(
      variables?: OutcomesQueryVariables,
      options?: UseQueryOptions<OutcomesQuery, TError, TData>
    ) => {
    
    return useQuery<OutcomesQuery, TError, TData>(
      variables === undefined ? ['outcomes'] : ['outcomes', variables],
      ferry<OutcomesQuery, OutcomesQueryVariables>(OutcomesDocument, variables),
      options
    )};

export const useInfiniteOutcomesQuery = <
      TData = OutcomesQuery,
      TError = unknown
    >(
      variables?: OutcomesQueryVariables,
      options?: UseInfiniteQueryOptions<OutcomesQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<OutcomesQuery, TError, TData>(
      variables === undefined ? ['outcomes.infinite'] : ['outcomes.infinite', variables],
      (metaData) => ferry<OutcomesQuery, OutcomesQueryVariables>(OutcomesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const UpdateOutcomeDocument = `
    mutation updateOutcome($updateOutcomeInput: UpdateOutcomeInput!) {
  updateOutcome(updateOutcomeInput: $updateOutcomeInput) {
    id
    name
  }
}
    `;

export const useUpdateOutcomeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateOutcomeMutation, TError, UpdateOutcomeMutationVariables, TContext>) => {
    
    return useMutation<UpdateOutcomeMutation, TError, UpdateOutcomeMutationVariables, TContext>(
      ['updateOutcome'],
      (variables?: UpdateOutcomeMutationVariables) => ferry<UpdateOutcomeMutation, UpdateOutcomeMutationVariables>(UpdateOutcomeDocument, variables)(),
      options
    )};

export const CreateQuestionDocument = `
    mutation createQuestion($input: CreateQuestionInput!) {
  createQuestion(createQuestionInput: $input) {
    type
    title
    id
    number
  }
}
    `;

export const useCreateQuestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateQuestionMutation, TError, CreateQuestionMutationVariables, TContext>) => {
    
    return useMutation<CreateQuestionMutation, TError, CreateQuestionMutationVariables, TContext>(
      ['createQuestion'],
      (variables?: CreateQuestionMutationVariables) => ferry<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, variables)(),
      options
    )};

export const CreateQuestionsOpportunitiesAndStrengthsDocument = `
    mutation createQuestionsOpportunitiesAndStrengths($createYesNoQuestions: CreateQuestionOpportunityAndStrengthInput!) {
  createQuestionsOpportunitiesAndStrengths(
    createYesNoQuestions: $createYesNoQuestions
  ) {
    createOpportunities {
      id
    }
    createQuestions {
      id
    }
    driver {
      name
      id
      number
    }
  }
}
    `;

export const useCreateQuestionsOpportunitiesAndStrengthsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateQuestionsOpportunitiesAndStrengthsMutation, TError, CreateQuestionsOpportunitiesAndStrengthsMutationVariables, TContext>) => {
    
    return useMutation<CreateQuestionsOpportunitiesAndStrengthsMutation, TError, CreateQuestionsOpportunitiesAndStrengthsMutationVariables, TContext>(
      ['createQuestionsOpportunitiesAndStrengths'],
      (variables?: CreateQuestionsOpportunitiesAndStrengthsMutationVariables) => ferry<CreateQuestionsOpportunitiesAndStrengthsMutation, CreateQuestionsOpportunitiesAndStrengthsMutationVariables>(CreateQuestionsOpportunitiesAndStrengthsDocument, variables)(),
      options
    )};

export const OpportunitiesDocument = `
    query opportunities($query: OpportunityDriverQuerySearch) {
  opportunities(query: $query) {
    id
    credit
    recommendation
  }
}
    `;

export const useOpportunitiesQuery = <
      TData = OpportunitiesQuery,
      TError = unknown
    >(
      variables?: OpportunitiesQueryVariables,
      options?: UseQueryOptions<OpportunitiesQuery, TError, TData>
    ) => {
    
    return useQuery<OpportunitiesQuery, TError, TData>(
      variables === undefined ? ['opportunities'] : ['opportunities', variables],
      ferry<OpportunitiesQuery, OpportunitiesQueryVariables>(OpportunitiesDocument, variables),
      options
    )};

export const useInfiniteOpportunitiesQuery = <
      TData = OpportunitiesQuery,
      TError = unknown
    >(
      variables?: OpportunitiesQueryVariables,
      options?: UseInfiniteQueryOptions<OpportunitiesQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<OpportunitiesQuery, TError, TData>(
      variables === undefined ? ['opportunities.infinite'] : ['opportunities.infinite', variables],
      (metaData) => ferry<OpportunitiesQuery, OpportunitiesQueryVariables>(OpportunitiesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const OpportunityDocument = `
    query opportunity($query: OpportunityQuerySearch!) {
  opportunity(query: $query) {
    id
    recommendation
  }
}
    `;

export const useOpportunityQuery = <
      TData = OpportunityQuery,
      TError = unknown
    >(
      variables: OpportunityQueryVariables,
      options?: UseQueryOptions<OpportunityQuery, TError, TData>
    ) => {
    
    return useQuery<OpportunityQuery, TError, TData>(
      ['opportunity', variables],
      ferry<OpportunityQuery, OpportunityQueryVariables>(OpportunityDocument, variables),
      options
    )};

export const useInfiniteOpportunityQuery = <
      TData = OpportunityQuery,
      TError = unknown
    >(
      variables: OpportunityQueryVariables,
      options?: UseInfiniteQueryOptions<OpportunityQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<OpportunityQuery, TError, TData>(
      ['opportunity.infinite', variables],
      (metaData) => ferry<OpportunityQuery, OpportunityQueryVariables>(OpportunityDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const QuestionDocument = `
    query question($id: String!) {
  question(id: $id) {
    type
    title
    labels {
      labelId
      labelName
      soa_id
      unit
      answer
      relatedReportField
    }
  }
}
    `;

export const useQuestionQuery = <
      TData = QuestionQuery,
      TError = unknown
    >(
      variables: QuestionQueryVariables,
      options?: UseQueryOptions<QuestionQuery, TError, TData>
    ) => {
    
    return useQuery<QuestionQuery, TError, TData>(
      ['question', variables],
      ferry<QuestionQuery, QuestionQueryVariables>(QuestionDocument, variables),
      options
    )};

export const useInfiniteQuestionQuery = <
      TData = QuestionQuery,
      TError = unknown
    >(
      variables: QuestionQueryVariables,
      options?: UseInfiniteQueryOptions<QuestionQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<QuestionQuery, TError, TData>(
      ['question.infinite', variables],
      (metaData) => ferry<QuestionQuery, QuestionQueryVariables>(QuestionDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const QuestionsDocument = `
    query questions($skip: Int, $take: Int, $query: QuestionQuerySearch, $sort: QuestionQuerySort) {
  questions(skip: $skip, take: $take, query: $query, sort: $sort) {
    data {
      id
      title
      type
      number
      isActive
    }
    meta {
      totalCount
    }
  }
}
    `;

export const useQuestionsQuery = <
      TData = QuestionsQuery,
      TError = unknown
    >(
      variables?: QuestionsQueryVariables,
      options?: UseQueryOptions<QuestionsQuery, TError, TData>
    ) => {
    
    return useQuery<QuestionsQuery, TError, TData>(
      variables === undefined ? ['questions'] : ['questions', variables],
      ferry<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, variables),
      options
    )};

export const useInfiniteQuestionsQuery = <
      TData = QuestionsQuery,
      TError = unknown
    >(
      variables?: QuestionsQueryVariables,
      options?: UseInfiniteQueryOptions<QuestionsQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<QuestionsQuery, TError, TData>(
      variables === undefined ? ['questions.infinite'] : ['questions.infinite', variables],
      (metaData) => ferry<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const RemoveOpportunityDocument = `
    mutation removeOpportunity($id: Int!) {
  removeOpportunity(id: $id)
}
    `;

export const useRemoveOpportunityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveOpportunityMutation, TError, RemoveOpportunityMutationVariables, TContext>) => {
    
    return useMutation<RemoveOpportunityMutation, TError, RemoveOpportunityMutationVariables, TContext>(
      ['removeOpportunity'],
      (variables?: RemoveOpportunityMutationVariables) => ferry<RemoveOpportunityMutation, RemoveOpportunityMutationVariables>(RemoveOpportunityDocument, variables)(),
      options
    )};

export const RemoveQuestionDocument = `
    mutation removeQuestion($id: String!) {
  removeQuestion(id: $id)
}
    `;

export const useRemoveQuestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveQuestionMutation, TError, RemoveQuestionMutationVariables, TContext>) => {
    
    return useMutation<RemoveQuestionMutation, TError, RemoveQuestionMutationVariables, TContext>(
      ['removeQuestion'],
      (variables?: RemoveQuestionMutationVariables) => ferry<RemoveQuestionMutation, RemoveQuestionMutationVariables>(RemoveQuestionDocument, variables)(),
      options
    )};

export const DeleteyesnoDocument = `
    mutation deleteyesno($driverId: Float!) {
  removeQuestionsAndOpportunities(driverId: $driverId)
}
    `;

export const useDeleteyesnoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteyesnoMutation, TError, DeleteyesnoMutationVariables, TContext>) => {
    
    return useMutation<DeleteyesnoMutation, TError, DeleteyesnoMutationVariables, TContext>(
      ['deleteyesno'],
      (variables?: DeleteyesnoMutationVariables) => ferry<DeleteyesnoMutation, DeleteyesnoMutationVariables>(DeleteyesnoDocument, variables)(),
      options
    )};

export const StrengthsByDriverIdDocument = `
    query StrengthsByDriverId($query: StrengthsByDriverIdQuerySearch!) {
  StrengthsByDriverId(query: $query) {
    strength
    id
    credit
  }
}
    `;

export const useStrengthsByDriverIdQuery = <
      TData = StrengthsByDriverIdQuery,
      TError = unknown
    >(
      variables: StrengthsByDriverIdQueryVariables,
      options?: UseQueryOptions<StrengthsByDriverIdQuery, TError, TData>
    ) => {
    
    return useQuery<StrengthsByDriverIdQuery, TError, TData>(
      ['StrengthsByDriverId', variables],
      ferry<StrengthsByDriverIdQuery, StrengthsByDriverIdQueryVariables>(StrengthsByDriverIdDocument, variables),
      options
    )};

export const useInfiniteStrengthsByDriverIdQuery = <
      TData = StrengthsByDriverIdQuery,
      TError = unknown
    >(
      variables: StrengthsByDriverIdQueryVariables,
      options?: UseInfiniteQueryOptions<StrengthsByDriverIdQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<StrengthsByDriverIdQuery, TError, TData>(
      ['StrengthsByDriverId.infinite', variables],
      (metaData) => ferry<StrengthsByDriverIdQuery, StrengthsByDriverIdQueryVariables>(StrengthsByDriverIdDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const UpdateQuestionDocument = `
    mutation updateQuestion($updateQuestionInput: UpdateQuestionInput!) {
  updateQuestion(updateQuestionInput: $updateQuestionInput) {
    id
    title
    type
  }
}
    `;

export const useUpdateQuestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateQuestionMutation, TError, UpdateQuestionMutationVariables, TContext>) => {
    
    return useMutation<UpdateQuestionMutation, TError, UpdateQuestionMutationVariables, TContext>(
      ['updateQuestion'],
      (variables?: UpdateQuestionMutationVariables) => ferry<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, variables)(),
      options
    )};

export const UpdateQuestionsOpportunitiesAndStrengthsDocument = `
    mutation updateQuestionsOpportunitiesAndStrengths($updateYesNoQuestions: UpdateQuestionOpportunityStrengthInput!) {
  updateQuestionsOpportunitiesAndStrengths(
    updateYesNoQuestions: $updateYesNoQuestions
  ) {
    driver {
      id
    }
  }
}
    `;

export const useUpdateQuestionsOpportunitiesAndStrengthsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateQuestionsOpportunitiesAndStrengthsMutation, TError, UpdateQuestionsOpportunitiesAndStrengthsMutationVariables, TContext>) => {
    
    return useMutation<UpdateQuestionsOpportunitiesAndStrengthsMutation, TError, UpdateQuestionsOpportunitiesAndStrengthsMutationVariables, TContext>(
      ['updateQuestionsOpportunitiesAndStrengths'],
      (variables?: UpdateQuestionsOpportunitiesAndStrengthsMutationVariables) => ferry<UpdateQuestionsOpportunitiesAndStrengthsMutation, UpdateQuestionsOpportunitiesAndStrengthsMutationVariables>(UpdateQuestionsOpportunitiesAndStrengthsDocument, variables)(),
      options
    )};

export const SaveMainTemplateDocument = `
    mutation saveMainTemplate($id: String!) {
  saveMainTemplate(id: $id)
}
    `;

export const useSaveMainTemplateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SaveMainTemplateMutation, TError, SaveMainTemplateMutationVariables, TContext>) => {
    
    return useMutation<SaveMainTemplateMutation, TError, SaveMainTemplateMutationVariables, TContext>(
      ['saveMainTemplate'],
      (variables?: SaveMainTemplateMutationVariables) => ferry<SaveMainTemplateMutation, SaveMainTemplateMutationVariables>(SaveMainTemplateDocument, variables)(),
      options
    )};

export const TemplateDocument = `
    query template($id: Int!) {
  template(id: $id) {
    id
    name
  }
}
    `;

export const useTemplateQuery = <
      TData = TemplateQuery,
      TError = unknown
    >(
      variables: TemplateQueryVariables,
      options?: UseQueryOptions<TemplateQuery, TError, TData>
    ) => {
    
    return useQuery<TemplateQuery, TError, TData>(
      ['template', variables],
      ferry<TemplateQuery, TemplateQueryVariables>(TemplateDocument, variables),
      options
    )};

export const useInfiniteTemplateQuery = <
      TData = TemplateQuery,
      TError = unknown
    >(
      variables: TemplateQueryVariables,
      options?: UseInfiniteQueryOptions<TemplateQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<TemplateQuery, TError, TData>(
      ['template.infinite', variables],
      (metaData) => ferry<TemplateQuery, TemplateQueryVariables>(TemplateDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const TemplatesDocument = `
    query templates($skip: Int, $take: Int, $query: TemplateQuerySearch, $sort: TemplateQuerySort) {
  templates(skip: $skip, take: $take, query: $query, sort: $sort) {
    data {
      id
      name
      isMain
      isMainTemplate
    }
    meta {
      totalCount
    }
  }
}
    `;

export const useTemplatesQuery = <
      TData = TemplatesQuery,
      TError = unknown
    >(
      variables?: TemplatesQueryVariables,
      options?: UseQueryOptions<TemplatesQuery, TError, TData>
    ) => {
    
    return useQuery<TemplatesQuery, TError, TData>(
      variables === undefined ? ['templates'] : ['templates', variables],
      ferry<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, variables),
      options
    )};

export const useInfiniteTemplatesQuery = <
      TData = TemplatesQuery,
      TError = unknown
    >(
      variables?: TemplatesQueryVariables,
      options?: UseInfiniteQueryOptions<TemplatesQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<TemplatesQuery, TError, TData>(
      variables === undefined ? ['templates.infinite'] : ['templates.infinite', variables],
      (metaData) => ferry<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const AllLanguagesDocument = `
    query allLanguages($sort: LanguageQuerySort) {
  languages(sort: $sort) {
    id
    name
    locale
    shortLabel
  }
}
    `;

export const useAllLanguagesQuery = <
      TData = AllLanguagesQuery,
      TError = unknown
    >(
      variables?: AllLanguagesQueryVariables,
      options?: UseQueryOptions<AllLanguagesQuery, TError, TData>
    ) => {
    
    return useQuery<AllLanguagesQuery, TError, TData>(
      variables === undefined ? ['allLanguages'] : ['allLanguages', variables],
      ferry<AllLanguagesQuery, AllLanguagesQueryVariables>(AllLanguagesDocument, variables),
      options
    )};

export const useInfiniteAllLanguagesQuery = <
      TData = AllLanguagesQuery,
      TError = unknown
    >(
      variables?: AllLanguagesQueryVariables,
      options?: UseInfiniteQueryOptions<AllLanguagesQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<AllLanguagesQuery, TError, TData>(
      variables === undefined ? ['allLanguages.infinite'] : ['allLanguages.infinite', variables],
      (metaData) => ferry<AllLanguagesQuery, AllLanguagesQueryVariables>(AllLanguagesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const CreatePartnerDocument = `
    mutation createPartner($createPartnerInput: CreatePartnerInput!) {
  createPartner(createPartnerInput: $createPartnerInput) {
    id
  }
}
    `;

export const useCreatePartnerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePartnerMutation, TError, CreatePartnerMutationVariables, TContext>) => {
    
    return useMutation<CreatePartnerMutation, TError, CreatePartnerMutationVariables, TContext>(
      ['createPartner'],
      (variables?: CreatePartnerMutationVariables) => ferry<CreatePartnerMutation, CreatePartnerMutationVariables>(CreatePartnerDocument, variables)(),
      options
    )};

export const AllPartnersDocument = `
    query allPartners($sort: PartnerQuerySort) {
  partners(sort: $sort) {
    name
    id
    created
    updated
  }
}
    `;

export const useAllPartnersQuery = <
      TData = AllPartnersQuery,
      TError = unknown
    >(
      variables?: AllPartnersQueryVariables,
      options?: UseQueryOptions<AllPartnersQuery, TError, TData>
    ) => {
    
    return useQuery<AllPartnersQuery, TError, TData>(
      variables === undefined ? ['allPartners'] : ['allPartners', variables],
      ferry<AllPartnersQuery, AllPartnersQueryVariables>(AllPartnersDocument, variables),
      options
    )};

export const useInfiniteAllPartnersQuery = <
      TData = AllPartnersQuery,
      TError = unknown
    >(
      variables?: AllPartnersQueryVariables,
      options?: UseInfiniteQueryOptions<AllPartnersQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<AllPartnersQuery, TError, TData>(
      variables === undefined ? ['allPartners.infinite'] : ['allPartners.infinite', variables],
      (metaData) => ferry<AllPartnersQuery, AllPartnersQueryVariables>(AllPartnersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const RemovePartnerDocument = `
    mutation removePartner($id: String!) {
  removePartner(id: $id)
}
    `;

export const useRemovePartnerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemovePartnerMutation, TError, RemovePartnerMutationVariables, TContext>) => {
    
    return useMutation<RemovePartnerMutation, TError, RemovePartnerMutationVariables, TContext>(
      ['removePartner'],
      (variables?: RemovePartnerMutationVariables) => ferry<RemovePartnerMutation, RemovePartnerMutationVariables>(RemovePartnerDocument, variables)(),
      options
    )};

export const ReportDataDocument = `
    query reportData($id: String!) {
  reports(id: $id)
}
    `;

export const useReportDataQuery = <
      TData = ReportDataQuery,
      TError = unknown
    >(
      variables: ReportDataQueryVariables,
      options?: UseQueryOptions<ReportDataQuery, TError, TData>
    ) => {
    
    return useQuery<ReportDataQuery, TError, TData>(
      ['reportData', variables],
      ferry<ReportDataQuery, ReportDataQueryVariables>(ReportDataDocument, variables),
      options
    )};

export const useInfiniteReportDataQuery = <
      TData = ReportDataQuery,
      TError = unknown
    >(
      variables: ReportDataQueryVariables,
      options?: UseInfiniteQueryOptions<ReportDataQuery, TError, TData>
    ) => {
    
    return useInfiniteQuery<ReportDataQuery, TError, TData>(
      ['reportData.infinite', variables],
      (metaData) => ferry<ReportDataQuery, ReportDataQueryVariables>(ReportDataDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};
