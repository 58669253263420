import { Button } from "antd";
import { authService, useAuth } from "lib/auth/exports";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalWithTitleDescriptionWithFormComponent } from "shared/components/ModalWithTitleDescriptionWithFormComponent";
import RequestAccess from "../../assets/images/request-access-for-app.gif";
import "./RequestAccessStyle.less";
import { performLogin } from "shared/utils/common-methods";

export const RequestAccessButton = (props: any) => {
  let reqAccess = function () {
    props.setIsRequestIsInProgres(true);
    authService.requestAdminAccess();
  };
  return (
    <Button
      disabled={props.isRequestIsInProgres}
      key="newRequestAccess"
      id="request-modal__footer-actions"
      className={
        "access-button-container " +
        (props.isRequestIsInProgres ? "disableButton" : "enableButton")
      }
      onClick={reqAccess}
    >
      {props.t("Request Access")}
    </Button>
  );
};

export const LogoutButton = (props: any) => {
  const { logout, setLogoutStatus } = useAuth();
  return (
    <Button
      key="logoutUnauthorized"
      className="logout-unauthorized"
      onClick={() => performLogin(setLogoutStatus, logout)}
    >
      {props.t("Log Out")}
    </Button>
  );
};

export const AccessRequestSentMessage = (props: any) => {
  return (
    <div className="request-sent-message-text">
      <span>
        {props.t(
          "Your request has been sent. Await authorization and reload the page."
        )}
      </span>
    </div>
  );
};

export const RequestAccessModal = () => {
  const { t } = useTranslation();
  const [isRequestIsInProgres, setIsRequestIsInProgres] = React.useState(false);

  return (
    <ModalWithTitleDescriptionWithFormComponent
      className="newRequestAccessModal"
      footer={null}
      width={920}
      closable={false}
      destroyOnClose={true}
    >
      <>
        <img
          data-testid="newRequestAccessModal"
          className="not-request-access-gif"
          src={RequestAccess}
          alt="Request"
        />
        <div className="text-content-container">
          <p className="title">
            {t(
              "You don’t have access to Cropwise Sustainability Admin Console"
            )}
          </p>
          <br />
          <p className="sub-title-text">
            {t(" The Cropwise Sustainability Admin Console allows")}
          </p>
          <p className="sub-title-text">
            {t(" authorized internal users to configure sustainability")}
          </p>
          <p className="sub-title-text">
            {t(" content within their country.")}
          </p>
          <br />
          <p className="sub-title-text">
            {t(
              " If you need access, you can request it and it will be reviewed"
            )}
          </p>
        </div>
        <div className="action-container">
          <div className="access-request-sent-message-container">
            {isRequestIsInProgres ? <AccessRequestSentMessage t={t} /> : null}
            {null}
          </div>
          <RequestAccessButton
            isRequestIsInProgres={isRequestIsInProgres}
            setIsRequestIsInProgres={setIsRequestIsInProgres}
            t={t}
          />
          <LogoutButton t={t} />
        </div>
      </>
    </ModalWithTitleDescriptionWithFormComponent>
  );
};

export const RequestAccessAddon: React.FC<{}> = (props: any) => {
  return <RequestAccessModal {...props} />;
};
