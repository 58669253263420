/* eslint-disable react-hooks/exhaustive-deps */
import { DISABLED_CARD_SHADE } from "../../../../constants.js";
import { getNextPageParam, isInViewport, paginationDefault } from "lib/common";
import { FrameworkContext } from "modules/framework";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Order, SortKey } from "shared/enums";
import useIsFrameworkPublishedOrInReview from "shared/hooks/useIsFrameworkPublishedOrInReview";
import {
  ContentLoader,
  ErrorDisplay,
  InlineLoader,
} from "shared/layout/exports";
import { sortData } from "shared/utils/common-methods";
import {
  Button,
  ButtonType,
  Card,
  Col,
  Row,
} from "syngenta-digital-cropwise-react-ui-kit";

export const CardLayout = (props: any) => {
  const params = paginationDefault;
  const [totalItems, setTotalItems] = useState(0);
  const { t } = useTranslation();
  let queryParamsWithSortKey = {
    ...params,
    sort: {
      order: Order.ASC,
      sortKey: props.dataKey === "templates" ? SortKey.NAME : SortKey.NUMBER,
    },
  };
  let queryParamsWithoutSortKey = {
    query: { ...(props.searchQuery ?? {}) },
  };
  const modulesHavingInfinitePagination = ["frameworks", "reports"].includes(
    props.dataKey
  );
  if (modulesHavingInfinitePagination) {
    queryParamsWithoutSortKey = {
      ...queryParamsWithoutSortKey,
      ...queryParamsWithSortKey,
    };
  }

  let nextPageParams: any = {
    getNextPageParam: modulesHavingInfinitePagination
      ? (lastPage: any, pages: any) =>
          getNextPageParam(lastPage, pages, {
            ...params,
            dataKey: props.dataKey,
          })
      : null,
  };

  if (props.dataKey === "questions") {
    nextPageParams = { ...nextPageParams, refetchOnMount: "always" };
  }

  let {
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
  } = props.query(queryParamsWithoutSortKey, nextPageParams);
  const { id } = useParams();
  const [sortedData, setSortedData] = useState<any>(null);
  useEffect(() => {
    try {
      sortData(props.dataKey, data?.pages[0][props.dataKey]);
      setSortedData(data);
      setTotalItems(data?.pages[0][props.dataKey].meta.totalCount);
    } catch (e) {
      setTotalItems(0);
    }
  }, [data]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (hasNextPage && isInViewport("infite-scroll-btn")) {
        fetchNextPage();
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [hasNextPage]);

  const { frameworkLoading } = useContext(FrameworkContext);
  const { isFrameworkPublished, isFrameworkPublishedOrInReview } =
    useIsFrameworkPublishedOrInReview();

  const showDriversByOutcome = (record: any) => {
    return (
      (props.dataKey === "drivers" &&
        record.number.split(".")[0] === props?.outcomeNumber) ||
      props.dataKey !== "drivers"
    );
  };

  let setGreyShadeForDisabledQuestions = function (record: {
    [k: string]: {};
  }) {
    if (record.name === undefined && record.isActive === false) {
      return DISABLED_CARD_SHADE;
    }
    return "white";
  };
  const itemLoadMessage = () => {
    return hasNextPage ? "" : t("Nothing more to load");
  };
  return (
    <ContentLoader isLoading={isLoading || frameworkLoading} isError={isError}>
      {sortedData?.pages !== undefined && (
        <Row className="landing-framework-container" data-cy="card-layout">
          {totalItems === 0 && (!isFrameworkPublished || !id) && (
            <ErrorDisplay
              title={`No ${props.moduleTitle} Created`}
              subTitle={`You can create a new ${props.moduleTitle.toLowerCase()} via the button below.`}
            />
          )}
          {sortedData?.pages.length > 0 &&
            sortedData?.pages?.map((group: any, i: any) =>
              group[props.dataKey]?.data?.map((record: any) => {
                return React.Children.map(props.children, (child) => {
                  if (showDriversByOutcome(record)) {
                    return (
                      <Col key={record.id} className="s-cards" xs={24} xl={8}>
                        <Card
                          className={record.isMain ? "lg-card" : ""}
                          style={{
                            background:
                              setGreyShadeForDisabledQuestions(record),
                          }}
                        >
                          {React.cloneElement(child, {
                            record,
                            isFrameworkPublishedOrInReview,
                          })}
                        </Card>
                      </Col>
                    );
                  }
                });
              })
            )}
        </Row>
      )}
      {sortedData && sortedData?.pages.length > 0 && totalItems > 0 && (
        <div style={{ textAlign: "center" }}>
          <Button
            id="infite-scroll-btn"
            type={ButtonType.link}
            onClick={fetchNextPage}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage ? (
              <InlineLoader text="Loading..." padding={false} />
            ) : (
              itemLoadMessage()
            )}
          </Button>
        </div>
      )}
      {(!isFrameworkPublishedOrInReview || !id) && (
        <Row className="buttonAlign">
          <Col md={24} lg={24}>
            {props.bottomAction}
          </Col>
        </Row>
      )}
    </ContentLoader>
  );
};
