import { Button, Card, Divider, Typography } from "antd";
// import { FrameworkPublishComponent } from "modules/framework/components/framework/FrameworkPublishComponent";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import EditSquare from "../../assets/images/edit-square.svg";
import RightIcon from "../../assets/images/icon-right.svg";
import OpenInNewTab from "../../assets/images/open-in-new.svg";
import "./style.less";

const { Title, Text } = Typography;

interface FrameworksByCountryCardProps {
  id: string;
  name: string;
  country: string;
  status: string;
  version: string;
}

function FrameworksByCountryCard({
  id,
  name,
  country,
  status,
  version,
}: FrameworksByCountryCardProps) {
  const { t } = useTranslation();
  const [isPublishModalVisible, setPublishModalVisible] = useState(false);

  const togglePublishModal = () => {
    setPublishModalVisible(!isPublishModalVisible);
  };

  const normalizedStatus =
    status === "draft"
      ? "unpublished"
      : status === "in_review"
      ? "awaiting-approval"
      : status;

  return (
    <>
      {/* {isPublishModalVisible && (
        <FrameworkPublishComponent
          onClose={togglePublishModal}
          framework={{ id, name, status, version }}
          status={status}
        />
      )} */}
      <Card
        data-cy="framework-card"
        className={`framework-by-country--card country--card ${
          status === "published" ? "published-card-outline" : ""
        }`}
        hoverable
      >
        <section className="framework-card__header">
          <section className="framework-card__title-open-row">
            <Card.Meta
              title={<Title data-cy="framework-name">{name}</Title>}
              className="framework-card__meta"
            />
            <Link to={id} data-cy="open">
              <Text>
                {t("Open ")}
                <img
                  src={OpenInNewTab}
                  alt={t("Open in New Tab")}
                  className="framework-card__box-icon"
                />
              </Text>
            </Link>
          </section>
          <section
            className={`framework-card__status-divider-row framework-card__status--${normalizedStatus}`}
          >
            <Text
              data-cy="framework-status"
              className={`framework-card__text--${normalizedStatus}`}
            >
              {t(normalizedStatus.replace(/-/g, " ").toUpperCase())}
            </Text>
            <Divider type="horizontal" className="divider" />
          </section>
          <section className="framework-card__edit-framework--spacing">
            <Link to={`edit-framework/${id}`} data-cy="edit">
              <Text>
                {t("Edit ")}
                <img
                  src={EditSquare}
                  alt={t("Edit Framework")}
                  className="framework-card__box-icon"
                />
              </Text>
            </Link>
          </section>
        </section>
        <section className="framework-card__country--header">
          <Text
            className="framework-card__country--label"
            data-cy="label-country"
          >
            {t("Country")}:
          </Text>
          <Text
            data-cy="country-name"
            className="framework-card__country--body"
          >
            {country}
          </Text>
        </section>
        <section className="framework-card__status--unpublished">
          {normalizedStatus === "unpublished" && (
            <Button
              data-cy="request-approval"
              block
              className="framework-card__status-button--unpublished--request-approval"
              onClick={togglePublishModal}
            >
              {t("Request Approval")}
            </Button>
          )}
          {normalizedStatus === "awaiting-approval" && (
            <Button
              data-cy="approve-or-deny"
              className="framework-card__status--awaiting-approval--approve-button"
              block
            >
              {t("Approve or Deny")}
            </Button>
          )}
          {normalizedStatus === "published" && (
            <section className="framework-card__status--published--approved-text">
              <Text className="framework-card__status--published--approved-text-color">
                {t("Approved")}
              </Text>
              <img
                className="framework-card__status--published--approved-checkmark-image"
                src={RightIcon}
                alt="Approved"
              />
            </section>
          )}
        </section>
      </Card>
    </>
  );
}

export default FrameworksByCountryCard;
