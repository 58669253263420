import React from "react";
import { InlineErrorMessage, InlineLoader } from "shared/layout/exports";
import { Layout } from "syngenta-digital-cropwise-react-ui-kit";

export const ContentLoader = (props: any) => {
  if (props.isLoading) {
    return <InlineLoader />;
  }
  if (props.isError) {
    return <InlineErrorMessage />;
  }
  return (
    <Layout className="page-content" id="scroll">
      {props.children}
    </Layout>
  );
};
