import React from "react";
import { Card, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import "./style.less";
import copy from "../../assets/images/copy.svg";
import version from "../../assets/images/version.svg";

interface FrameworkByPartnerCardProps {
  name: string;
}

function FrameworkByPartnerCard({ name }: FrameworkByPartnerCardProps) {
  const { Meta } = Card;
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Card
          className="framework-card custom-card framework-by-partner--card"
          hoverable
          cover={
            <div className="card-cover">
              <div className="card-status">{t("published")}</div>
              <img
                loading="lazy"
                className="copy-icon"
                src={copy}
                alt={t("Copy icon")}
              />
            </div>
          }
        >
          <Meta title={name} className="card-meta" />
          <Row
            gutter={[16, 16]}
            justify="space-between"
            className="card-info-row"
          >
            <Col xs={6} xl={12} className="partner-info">
              <span className="partner-label">{t("Partner")}:</span> {t("ADM")}
            </Col>
            <Col xs={6} xl={12} className="version-info">
              {t("Version")}: <span className="version-number">2.0.0</span>
              <img
                loading="lazy"
                className="version-icon"
                src={version}
                alt="Version icon"
              />
            </Col>
          </Row>

          <Button type="primary" block className="framework-card-open-button">
            {t("Open")}
          </Button>
        </Card>
      </Col>
    </Row>
  );
}

export default FrameworkByPartnerCard;
