import React, { ErrorInfo, ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;
const Label = styled.h1`
  display: inline-block;
  font-weight: 500;
  font-size: 46px;
  color: rgb(46, 51, 125);
`;

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}
class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
    this.setState({ hasError: true, error, errorInfo });
  }
  public render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Label>Something went wrong</Label>
          <p>{"Please try after some time"}</p>
        </Container>
      );
    }
    return this.props?.children || null;
  }
}

export { ErrorBoundary };
