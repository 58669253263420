import { Collapse } from "antd";
import React from "react";
import "./style.less";
const { Panel } = Collapse;

export interface CollapseItem {
  key: number;
  header: React.ReactNode;
  content: React.ReactNode | string;
}
interface CollapseProps {
  items: CollapseItem[];
  ghost: boolean;
  accordion?: boolean;
  defaultActiveKey: string[];
}
export const CustomCollapse: React.FC<CollapseProps> = ({
  ghost,
  items,
  accordion = false,
  defaultActiveKey,
}: CollapseProps) => {
  return (
    <Collapse
      className="custom-collapse"
      accordion={accordion}
      defaultActiveKey={defaultActiveKey}
      expandIconPosition="left"
      ghost={ghost}
    >
      {items.map((item) => (
        <Panel data-cy={`collapse-header`} header={item.header} key={item.key}>
          {item.content}
        </Panel>
      ))}
    </Collapse>
  );
};
