export const paginationDefault = { skip: 0, take: 12 };

export const getNextPageParam = (lastPage: any, pages: any, params: any) => {
  const nextPage = pages.length + 1;
  try {
    return (lastPage[params.dataKey].meta.totalCount > pages.length* params.take) ?
    { skip: (((nextPage-1) * params.take + 1)-1) , take: params.take } :
    undefined;
  } catch(e) {
    console.log(e, params)
    return undefined;
  }
}

export const isInViewport = (elementId: any) => {
  const element = document.getElementById(elementId);
  if (element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  return false;
}
