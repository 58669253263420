import { FrameworkEntity } from "generated/graphql";
import { FrameworkContext } from "modules/framework";
import { useContext } from "react";

const useIsFrameworkPublishedOrInReview = (frameworkData?: FrameworkEntity) => {
  const { framework } = useContext(FrameworkContext);
  const frameworkDetails = Object.keys(framework).length
    ? framework
    : frameworkData;
  const isFrameworkPublished = frameworkDetails?.status === "published";
  const isFrameworkPublishedOrInReview = ["published", "in_review"].includes(
    frameworkDetails?.status ?? ""
  );

  return { isFrameworkPublished, isFrameworkPublishedOrInReview };
};

export default useIsFrameworkPublishedOrInReview;
