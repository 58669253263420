import { Checkbox } from "antd";
import React from "react";

interface MultiSelectDropdownProps {
  menu: React.ReactElement;
  handleSelectAll: () => void;
  selectAll: boolean;
}
export const DropdownSelectAll: React.FC<MultiSelectDropdownProps> = ({
  menu,
  handleSelectAll,
  selectAll,
}) => {
  return (
    <>
      <Checkbox
        style={{ padding: "6px 15px" }}
        checked={selectAll}
        onChange={handleSelectAll}
      >
        Select All
      </Checkbox>
      {menu}
    </>
  );
};
