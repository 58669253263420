import { Typography } from "antd";
import { startCase } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import SelectedIcon from "../../assets/images/selected-icon.svg";
import { FrameworkType } from "./FrameworksToggleAndCards";
import "./style.less";

const { Text } = Typography;
interface ToggleButtonType {
  selected: FrameworkType;
  toggleSelection: (frameworkType: FrameworkType) => void;
  setSelected: React.Dispatch<React.SetStateAction<FrameworkType>>;
}
export const ToggleButton = (props: ToggleButtonType) => {
  const { selected, toggleSelection } = props;
  const { t } = useTranslation();

  const toggleButtonKeys: FrameworkType[] = ["country", "partner"];
  return (
    <>
      {toggleButtonKeys.map((toggleButtonKey) => {
        const isSelected = selected === toggleButtonKey;
        return (
          <section
            data-testid={`${toggleButtonKey}-toggle-button`}
            className={`toggle-button ${toggleButtonKey} ${
              isSelected ? "selected-background" : "unselected-background"
            }`}
            onClick={() => toggleSelection(toggleButtonKey)}
          >
            <section className="toggle-button-content">
              {isSelected && (
                <img
                  src={SelectedIcon}
                  alt={t("checked")}
                  className="toggle-checked-icon"
                />
              )}
              <Text
                className={`toggle-button-text ${
                  isSelected ? "selected-text" : "unselected-text"
                }`}
              >
                {t(startCase(toggleButtonKey))}
              </Text>
            </section>
          </section>
        );
      })}
    </>
  );
};
