import { Select } from "antd";
import React from "react";
import "./styles.less";

const FormSelect = (props: any) => {
  const {
    showSearch,
    name,
    style,
    items,
    valueKey,
    bordered,
    maxTagCount,
    mode,
    onChange,
    onFocus,
    disabled,
    onDropdownVisibleChange,
    onClick,
    loading,
  } = props;
  return (
    <Select
      size="large"
      showSearch={showSearch ?? false}
      dropdownStyle={props.dropdownStyle}
      data-cy={`${name}-select`}
      className="selectdrop"
      placeholder={props?.placeholder ?? `Select`}
      style={style}
      variant={bordered ?? "borderless"}
      popupMatchSelectWidth={props?.popupMatchSelectWidth ?? false}
      maxTagCount={maxTagCount}
      mode={mode}
      onChange={onChange}
      onFocus={onFocus}
      defaultValue={props?.defaultValue}
      onClick={onClick}
      disabled={disabled}
      onDropdownVisibleChange={onDropdownVisibleChange}
      loading={loading}
      filterOption={(input, option: any) => {
        return option?.children?.toLowerCase().includes(input.toLowerCase());
      }}
    >
      {items?.length &&
        items.map((item: any) => {
          const value =
            typeof item === "object"
              ? { val: item.id, display: item[valueKey || "value"] }
              : { val: item, display: item };
          return (
            <Select.Option
              data-cy={value.val}
              key={value.val}
              value={value.val}
            >
              {value.display}
            </Select.Option>
          );
        })}
    </Select>
  );
};
const FormHandler: any = () => {};

FormHandler.Select = FormSelect;

export { FormHandler };
