import {
  getAccessToken,
  getOAuthUrl,
  isAccessTokenAvailable,
} from "lib/auth/authService";

export const ferry = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit["headers"]
): (() => Promise<TData>) => {
  return async () => {
    const resJson = await apiHandler(query, variables, options);

    if (resJson.errors === 401) {
      window.location.href = getOAuthUrl();
    }

    if (resJson.errors) {
      const { message } = resJson.errors[0] || "Error..";
      throw new Error(message);
    }

    return resJson.data;
  };
};

export async function apiHandler<TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit["headers"]
) {
  const response = await fetch(process.env.REACT_APP_API_URL as string, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(isAccessTokenAvailable()
        ? { Authorization: `Bearer ${getAccessToken()}` }
        : {}),
      ...(options ?? {}),
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  const json = await response.json();
  return json;
}
