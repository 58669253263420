import { CardLayout } from "modules/framework/components/card-layout/CardLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const LayoutModule = (props: any) => {
  const { cardLayoutProps, cardComponent: CardComponent } = props;
  const { t } = useTranslation();
  const [initCreate, setInitCreate] = useState(false);

  let toggleModal = function () {
    setInitCreate(!initCreate);
  };

  return (
    <CardLayout
      {...cardLayoutProps}
      bottomAction={
        <>
          {cardLayoutProps.moduleTitle !== "Framework" && (
            <button
              onClick={toggleModal}
              data-cy={`create-new-${cardLayoutProps.moduleTitle}-button`}
            >
              + {t(`Create New ${cardLayoutProps.moduleTitle}`)}
            </button>
          )}
          {props.children &&
            initCreate &&
            React.cloneElement(props.children, { toggleModal })}
        </>
      }
    >
      {CardComponent}
    </CardLayout>
  );
};

export default LayoutModule;
